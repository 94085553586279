.b-stop_fake_form,
.b-stop_fake_feed {
	&-message {
		&.m-error {
			&:before {
				font-family: $font_family;
			}
		}
	}
}
