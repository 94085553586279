@include plp-refinements(
	$toggle_grid-size: 20px,
	$icon_grid: grid63-filled,
	$icon_grid-empty: grid63-filled,
	$icon_list: grid42-filled,
	$icon_list-empty: grid42-filled
)

.b-sticky-refinement {
	background-color: $color-black;

	&,
	.pagination a {
		color: $color-grey2;
	}

	.items-per-page,
	.b-pagination-list {
		margin: 0 0 0 10px;
		padding: 0;
	}
}

.refinement {
	@include respond-to(not-desktop) {
		@include scrollbar($color-grey_light4);
		border-bottom: 1px solid $color-grey_light2;
	}

	.toggle {
		@include transition(color, .1s);
		font-weight: 400;

		@include respond-to(desktop) {
			padding: 0 10px;

			&:hover,
			&.expanded {
				color: $color-white;
			}
		}

		@include respond-to(not-desktop) {
			@include font(13px);
			color: $color-text;
			font-weight: 600;
		}

		&-content {
			min-width: 100%;
			right: auto;
			background-color: $color-grey_light4;

			@include respond-to(not-desktop) {
				overflow: auto;
				max-height: 40vh;
				border-top: 1px solid $color-grey_light2;
			}
		}
	}

	&-wrapper {
		@include respond-to(desktop) {
			float: left;
		}

		@include respond-to(not-desktop) {
			background-color: $color-grey_light4;
		}

		@include respond-to(tablet) {
			width: 200%;
		}
	}

	.clear-refinement {
		margin: 0 0 20px percentage(1/2);
		text-align: center;

		a {
			margin: 0;
			padding: 0;
		}
	}

	ul {
		@include columns(2);
		padding: 10px;

		@include respond-to(desktop) {
			width: 440px;
		}
	}

	a {
		@include font(12px);
		line-height: 18px;
		color: $color-grey;
		padding-left: 24px;
		text-transform: uppercase;
		min-width: 0;
		margin: 10px;

		&:before {
			background-color: $color-white;
			border-color: $color-grey_light2;
			color: $color-grey;
			padding: 1px;
		}

		&:hover {
			&:before {
				@include transition(border-color, .1s);
				border-color: $color-black;
			}
		}
	}

	.selected a {
		@include icon(form-checkbox, before);

		&:before {
			@include font(14px, false);
			line-height: 14px;
			background-color: $color-white;
		}
	}

	&.category-refinement {
		ul {
			@include respond-to(not-desktop) {
				@include columns(1);
			}
		}
	}

	&.m-medium {
		@include respond-to(desktop) {
			ul {
				width: 650px;
				@include columns(3);
			}

			.clear-refinement {
				margin-left: percentage(2/3);
			}
		}
	}

	&.m-large {
		@include respond-to(desktop) {
			ul {
				width: 860px;
				@include columns(4);
			}

			.clear-refinement {
				margin-left: percentage(3/4);
			}
		}
	}
}

.b-active_filters {
	margin: 0;
	border: 0;
	background-color: $color-black2;
}

.breadcrumb-relax:after {
	color: $color-grey_light;
}

.b-filter_by-button {
	color: $color-white;
	float: left;
	font-weight: 400;

	@include respond-to(desktop) {
		padding: 0 10px 0 20px;

		&:before {
			display: none;
		}
	}
}

.b-sort_by {
	margin-left: 10px;

	@include respond-to(not-desktop) {
		border-left: 0;
		margin: 0;
		background-color: $color-black;
	}

	&-button {
		font-weight: 400;

		&.toggle {
			@include transition(color, .1s);
			color: $color-white;
		}
	}

	&-content {
		margin-top: 0;
		background-color: $color-grey_light4;
	}
}

.search-result-options {
	@include respond-to(tablet) {
		width: auto;
	}
}

.results-hits {
	margin-left: 30px;
}

.b-product_view {
    float: right;
    margin: 0 15px 0 0;
	text-align: right;

	&-title {
		display: inline-block;
		font-weight: 400;
		color: $color-white;
	}

	&-item {
		color: $color-grey2;
		font-weight: 400;

		&.m-active {
			color: $color-white;

			&:after {
				border: 0;
			}
		}
	}
}

.b-toggle_grid-icon {
	&.m-grid {
		&:before {
			color: $color-white;
		}
	}

	&.m-list {
		&:before {
			color: $color-grey2;
		}
	}
}

.wide-tiles {
	.b-toggle_grid-icon {
		&.m-grid {
			&:before {
				color: $color-grey2;
			}
		}

		&.m-list {
			&:before {
				color: $color-white;
			}
		}
	}
}
