.b-banner {
	&-video:before {
		@extend %b2b-loader;
	}

	&-title {
		@extend %heading-1;
	}

	&-subtitle {
		@extend %heading-3;
	}	
}
