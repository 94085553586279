.b-pdp {
	&-store_availability {
		display: none;
	}

	&-add_to_cart_form {
		margin-bottom: 25px;

		@include respond-to(desktop) {
			@include font();
			width: 100%;
		}
	}

	&-product_actions,
	&-help_action {
		margin-bottom: 15px;

		@include respond-to(desktop) {
			float: left;
			max-width: 50%;
		}

		@include respond-to(not-desktop) {
			width: 100%;
		}
	}


	&-add_to_cart {
		width: 100%;

		&.m-large {
			padding-left: 10px;
			padding-right: 10px;
		}

		> span {
			pointer-events: none;
		}
	}

	&-favorite_button.m-text,
	&-help_link.m-text {
		@include font(13px, 1px);
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;

		&:before {
			display: inline-block;
			margin: -3px 5px 0 0;
			@include font(15px, false);
		}
	}

	&-favorite_button.m-text {
		@include icon(star);

		&.b-favorite-added,
		&:hover {
 			&:before {
				content: icon-char(star-filled);
			}
		}

		&.b-favorite-added:hover {
 			&:before {
				content: icon-char(star);
			}
		}

		&:hover {
			.b-favorite_message-added {
				display: none;
			}

			.b-favorite_message-remove {
				display: inline;
			}
		}
	}

	&-gift_registry {
		display: none;
	}
}
