@mixin b-store_locator(
  $gutter: 20px,
  $gutter-small: 20px
) {
  .b-store_locator {
    .hidden {
      display: none;
    }

    &-content {
      &.hidden {
        display: block;
        overflow: hidden;
        height: 0;
        margin: 0;
        padding: 0;
      }
    }

    &-map {
      position: relative;
      height: 100%;
      overflow: hidden;
    }

    &-search_form {
      .f-textinput,
      .f-select,
      .b-button {
        width: 100%;
        margin: 0;
      }
    }

    .b-store_result-actions,
    &-details_actions {
      float: right;
    }

    .b-action_link {
      vertical-align: top;
      margin-left: 15px;
      @include font(0, 0);
      text-decoration: none;

      &:hover {
        opacity: 0.75;
      }

      &:before {
        @include font(26px, false);
      }
    }

    &-details_store {
      overflow: hidden;
    }

    &-details_map {
      height: 200px;
    }
  }

  .b-direction {
    $point-indent: $input-height + 10;

    &-form {
      max-width: 400px;

      .f-field-button {
        margin: 0 0 0 $point-indent;

        .b-button {
          width: 100%;
        }
      }

      .f-error {
        margin-left: $point-indent;
      }
    }

    &-error {
      margin-top: 10px;
    }

    &-mode {
      margin: 0 -3% $gutter 0;
      @include font(0, 0);

      .b-button {
        width: 22%;
        padding: 10px;
        margin-right: 3%;

        &:before {
          @include font(26px, false);
        }

        > span {
          display: none;
        }
      }
    }

    &-start,
    &-end {
      @include flexbox;
      @include wrap;

      &:after {
        content: 'A';
        @include order(1);
        width: $input-height;
        height: $input-height;
        margin-right: 10px;
        border-radius: 100%;
        background-color: $color-grey2;
        @include font(18px, 0);
        font-weight: 700;
        line-height: $input-height;
        text-align: center;
        color: $color-white;
      }

      .f-label {
        @include flex(100%);
      }

      .f-textinput {
        @include order(2);
        @include flex(1);
      }

      .f-error {
        @include order(3);
        @include flex(100%);
      }
    }

    &-end {
      &:after {
        content: 'B';
      }
    }

    &-route_duration {
      &:before {
        content: '- ';
      }
    }

    &-route_table {
      word-break: break-word;
    }
  }

  .b-route {
    &-distance,
    &-duration {
      min-width: 55px;
    }
  }

  .b-store_share {
    &-title {
      @extend %dialog-title;
    }

    &-content {
      max-width: 360px;
      margin: 0 auto;
    }

    &-message {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  @include respond-to(not-phone) {
    .b-store_locator {
      &-search_form {
        @include flexbox;
        @include wrap;
        max-width: $content-width;
        margin: 0 auto;
      }

      &-search_country,
      &-search_distance {
        padding: 0 $gutter;
      }

      &-search_country {
        width: 60%;
      }

      &-search_distance {
        width: 40%;
      }

      &-no_results {
        max-width: $content-width;
        margin: 10px auto 0;
        padding: 0 $gutter;
      }

      &-details_title {
        margin-bottom: $gutter;
      }

      &-details_info,
      &-details_map_direction {
        display: block;
      }

      &-details_info {
        margin-bottom: $gutter;
        padding-bottom: $gutter;

        .b-store-image {
          float: right;
          max-width: none;
          margin: 0;

          > img {
            width: 400px;
          }
        }

        .b-store-info,
        .b-store-hours,
        .b-store-events {
          float: left;
          padding-right: 2*$gutter;
        }

        .b-store-events {
          &:nth-child(even) {
            margin-top: 1.5*$gutter;
          }
        }
      }

      &-details_map,
      &-details_direction {
        float: left;
        width: 50%;
      }
    }
  }

  @include respond-to(desktop) {
    .b-store_locator {
      &-elements,
      &-details {
        &.b-content {
          margin-top: 0;
        }
      }

      &-search {
        padding: $gutter 0;
      }

      &-search_form {
        .f-field {
          @include flex(100%);
          margin-bottom: 0;
          padding-right: $gutter;
        }

        .f-field-button {
          padding-right: 0;
        }

        .b-button {
          white-space: nowrap;

          &:before {
            vertical-align: top;
          }
        }
      }

      &-search_country,
      &-search_distance {
        @include flexbox;
      }

      &-results {
        position: relative;
        @include calc(height, '100vh - #{$header-height} - 70px - 93px');
        min-height: 400px;
        overflow: hidden;
      }

      &-results_map {
        overflow: hidden;
        height: 100%;
      }

      &-results_list {
        float: right;
        width: 500px;
        max-width: 40%;
        height: 100%;
        padding: $gutter;

        .b-store_result-list {
          height: 93%;
        }
      }

      &-details {
        padding: $gutter;
      }

      &-details_info {
        .b-store-image {
          width: 50%;
        }
        .owl-item img {
          width: 100%;
        }
        .b-store-info,
        .b-store-hours,
        .b-store-events {
          width: 25%;
        }
      }

      &-details_map {
        height: 450px;
        padding-right: 2*$gutter;
      }
    }
  }

  @include respond-to(tablet) {
    .b-store_locator {
      &-elements,
      &-details {
        padding: $gutter;
      }

      &-results {
        margin-top: $gutter;
      }

      &-results_map {
        height: 300px;
        margin-top: $gutter;
      }

      &-search {
        margin: 0 -#{$gutter};

        .f-field {
          margin-bottom: 10px;

          &-button {
            margin-bottom: 0;
          }

          + .f-field-select {
            padding-left: 10px;
          }
        }
      }

      &-search_country {
        .f-field {
          float: left;
          width: 50%;

          &-button {
            width: 100%;
          }
        }
      }

      &-details_info {
        .b-store-info,
        .b-store-hours,
        .b-store-events {
          width: percentage(1/3);
        }
        .b-store-image {
          width: percentage(2/3);
        }
        .b-store-hours {
          clear: left;
          margin-top: 1.5*$gutter;
        }
        .b-store-events {
          float: right;
        }
      }

      &-details_map {
        height: 400px;
        padding-right: $gutter;
      }
    }
  }

  @include respond-to(phone) {
    .b-store_locator {
      &-elements,
      &-details {
        padding: $gutter-small;
      }

      &-results {
        margin-top: $gutter-small;
      }

      &-results_map {
        height: 300px;
        margin-top: $gutter-small;
      }

      &-details_title {
        margin-bottom: $gutter-small;
      }

      &-details_store {
        @include toggle-tabs;

        .toggle-content {
          display: block;
          height: 0;
          overflow: hidden;
        }

        .expanded + .toggle-content {
          height: auto;
        }
      }

      &-details_button {
        width: 50%;
      }

      &-details_button ~ &-details_button {
        border-left: 0;
      }

      &-details_info {
        .b-store-image {
          float: none;
          width: auto;
          max-width: none;
          margin: 0 0 $gutter;
        }

        .b-store-info,
        .b-store-hours,
        .b-store-events {
          margin-bottom: $gutter;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-details_map {
        margin-bottom: $gutter;
      }
    }
  }
}

.b-store_booking {
  margin: 0 auto;
  width: fit-content;
  padding: 0 20px;
  .b-title {
    font-weight: 600;
  }
  h2 {
    font-size: 26px;
  }
  h4 {
    font-size: 16px;
  }
  .f-field-date {
    display: inline-block;
    height: 35px;
    width: 200px;
    vertical-align: top;
    max-width: 100%;
    padding: 5px 12px;
    font-size: 1rem;
    letter-spacing: 0.75px;
    border: 1px solid $color-grey_light2;
    outline: none;
    resize: none;
    appearance: none;
  }
  &-store {
    margin: 20px 0 30px;
  }
  &-submit {
    margin-top: 30px;
  }
  &-terms {
    margin-top: 50px;
    font-size: 11px;
    color: $color-grey2;
    a {
      color: $color-grey2;
    }
  }
  &-confirmation {
    .b-button {
      margin-top: 15px;
    }
  }
  &-success,
  &-error {
    text-align: center;
    font-weight: 700;
    margin: 30px 0;
    font-size: 20px;
  }
  &-error {
    color: $color-red;
  }
  &-details {
    margin: 30px 0;
  }
}

.b-title_primary {
  display: none;
}

.b-title_box + .b-content:has(.b-store_booking) {
  margin-top: 20px;
}

// Additions to jquery UI
.ui-datepicker {
  min-width: 260px;
  background-color: $color-white !important;
  padding: 10px 15px !important;
  border: 1px solid $color-grey_light2;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjE4MjA0IDEuMTcxNTJDMS4yNzkzNyAxLjA3NjAzIDEuNDM1MjQgMS4wNzYwNCAxLjUzMjU2IDEuMTcxNTNMNS4zMjA5OSA0Ljg4ODk1TDEuNTMyMzEgOC42MDU2N0MxLjQzNTEyIDguNzAxMDEgMS4yNzk1IDguNzAxIDEuMTgyMzIgOC42MDU2NUMxLjA4MjQ3IDguNTA3NjkgMS4wODI0OCA4LjM0Njg2IDEuMTgyMzMgOC4yNDg5MUw0LjYwNjg4IDQuODg5NDVMMS4xODIwMyAxLjUyODc1QzEuMDgyMDYgMS40MzA2NiAxLjA4MjA3IDEuMjY5NjEgMS4xODIwNCAxLjE3MTUyWiIgZmlsbD0iYmxhY2siIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMC44Ii8+Cjwvc3ZnPgo=);
    width: 100%;
    height: 100%;
    font-size: 20px;
    line-height: 1;
  }
}
.ui-datepicker-prev {
  transform: rotate(180deg);
}
