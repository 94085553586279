.intl-tel-input {
    &.error {
        margin-bottom: 15px;
        input, input[type=text], input[type=tel] {
            border-color: $color-error;
        }

        .error {
            height: 0;
        }
    }

    &.allow-dropdown, &.separate-dial-code {
        .flag-container {
            color: $color-text;
        }
    }

    &.separate-dial-code {
        .selected-dial-code {
            font-size: 12px;
        }
    }


}

.b-address-form .intl-tel-input {
    width: 75%;

    input, input[type=text], input[type=tel] {
        width: 100%;
    }
    &.error {
        margin-bottom: 0;
        display: inline-block;
        margin-left: 0!important;
        letter-spacing: 1px;
        font-size: 0;
        vertical-align: middle;
        .error {
            height: 0;
            margin-left: 0;
        }

        + .f-field-tooltip {
            margin-top: 15px;

            + .f-caption {
                margin-top: 15px;
            }
        }
    }
}