// Block with text
.b-text,
%b-text {
	// Headings
	h1 {
		@include font($font_size-h1);
		margin-bottom: 0.5em;
	}
	
	h2 {
		@include font($font_size-h2);
		margin-bottom: 0.5em;
	}
	
	h3 {
		@include font($font_size-h3);
		margin-bottom: 0.5em;
	}
	
	h1, h2, h3, h4, h5, h6,
	p, ul, ol {
		margin: 0.5em 0;
		
		&:first-child {
			margin-top: 0;
		}
	}
	
	// Lists 
	ul {
		&, li {
			list-style: inside square;
		}
	}
	
	ol {
		&, li {
			list-style: inside decimal;
		}
	}
	
	li {
		margin: 0.25em 0;
	}
	
	em {
		font-style: italic;
	}
}


// Tables
table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

tr {
	border-bottom: 1px solid $color-grey2;
	
	&:last-child {
		border-bottom: 0;
	}
}

th,
td {
	padding: 10px 0;
	vertical-align: middle;
	text-align: left;
}


// Links 
a {
	outline: none;
	
	&:hover {
		text-decoration: none;
	}
}


// Other elements
b,
strong {
	font-weight: 700;
}
