@mixin plp-refinements-button {
	@include icon(arrow-down);

	padding: 0 20px;
	font-size: $font_size-small;
	font-weight: 700;
	text-transform: uppercase;
	@include text-ellipsis;

	&.expanded:before {
		content: icon-char(arrow-up);
	}

	&:before {
		float: right;
		font-size: 4px;
		margin-left: 5px;
	}

	&:after {
		display: none;
	}
}

@mixin plp-refinements-link {
	position: relative;
	display: inline-block;
	min-width: 120px;
	color: $color-black2;
	margin-bottom: 10px;
	padding-left: 20px;
	text-decoration: none;

	&:before {
		content: '';
		position: absolute;
		left: 0; top: 0;
		width: 14px; height: 14px;
		border: 1px solid $color-black2;
	}
}

@mixin plp-refinements(
	$device: all,

	$toggle_grid-size: 22px,

	$icon_grid: grid4-filled,
	$icon_grid-empty: grid4-empty,
	$icon_list: grid3-filled,
	$icon_list-empty: grid3-empty,

	$refinements-height: 48px,
	$refinements-height_mobile: 45px

) {
	%refinements-button {
		display: inline-block;
		vertical-align: top;

		&,
		&:before {
			line-height: $refinements-height;

			@include respond-to(not-desktop) {
				line-height: $refinements-height_mobile;
			}
		}

		@include plp-refinements-button;
	}

	%refinements-dropdown {

	}

	@if $device == all or $device == desktop {
		.b-search_results-refinements {
			float: left;
			position: relative;
		}

		.b-sticky-refinement {
			position: relative;
			min-width: 100%;
			background: $color-refinements;
			z-index: $z-refinements;
		}

		.refinement {
			float: left;
		}

		.refinement {
			.toggle-content {
				z-index: $z-refinements;

				@include respond-to(desktop) {
					position: absolute;
					top: 100%; left: 0;
				}
			}

			ul {
				padding: 20px;
				columns: 3;

				@include respond-to(phone) {
					columns: 2;
				}

				&#category-level-2 {
					columns: 1;
				}
			}

			&-clear_all {
				@extend %b-button;

				font-size: 10px;
				line-height: 10px;
				margin: 5px 15px 5px 0;
				padding: 5px 10px;
				text-decoration: none;
				vertical-align: middle;

				&-wrapper {
					padding: 10px;
					border-bottom: 1px solid $color-grey_light2;
					text-align: center;
				}
			}
		}

		.b-sort_by {
			display: inline-block;
			vertical-align: middle;

			&-button {
				@extend %refinements-button;
			}

			&-content {
				background: $color-white-transparent;
				margin-top: 1px;
				padding: 0 20px;
				position: absolute;
				right: 0;
				top: 100%;
				text-align: left;
				width: 200px;
				z-index: $z-refinements;

				&:before,
				&:after {
					content: '';
					display: block;
					height: 20px;
				}
			}

			&-item {
				margin: 0 0 5px;
			}

			&-link {
				color: $color-black2;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.search-result-options {
			float: right;
			text-align: right;

			@include respond-to(not-desktop) {
				float: none;
			}

			.items-per-page,
			.b-pagination {
				display: inline-block;
				vertical-align: top;
				line-height: $refinements-height;

				@include respond-to(not-desktop) {
					line-height: $refinements-height_mobile;
				}
			}

			.items-per-page {
				@include respond-to(not-desktop) {
					display: none;
				}
			}

			.b-pagination {
				@include respond-to(not-desktop) {
					display: block;
					text-align: center;
				}
			}
		}

		.items-per-page {
			@include font(0, 0);

			label {
				display: none;
			}

			#grid-paging-header {
				@extend %f-select;
				vertical-align: middle;
				width: auto;
			}

			.sort-Go-Button {
				display: none;
			}

			@include respond-to(not-desktop) {
				display: none;
			}
		}

		.b-active_filters .breadcrumb-refinement-value {
			display: inline-block;
			margin-right: 15px;
			padding: 5px 0;;
		}

		.refinement-qty,
		.refinement-header,
		.breadcrumb-refined-by,
		.breadcrumb-refinement-name {
			display: none;
		}

		.breadcrumb-relax:after {
			color: $color-black2;
			display: inline-block;
			font-size: 7px;
			line-height: 16px;
			margin: 0 0 0 5px;
			vertical-align: bottom;
		}

		.b-product_view {
			margin: 0 35%;
			white-space: nowrap;
			text-align: center;

			@include respond-to(phone) {
				display: none;
			}

			&-title,
			&-item {
				vertical-align: middle;
				margin: 0 7px;
				font-size: $font_size-small;
				font-weight: 700;
				line-height: $refinements-height;
				color: $color-grey;
				text-transform: uppercase;
				text-decoration: none;

				@include respond-to(not-desktop) {
					line-height: $refinements-height_mobile;
				}
			}

			&-title {
				display: none;
			}

			&-item {
				position: relative;
				display: inline-block;
				cursor: pointer;

				&.m-active {
					color: $color-text;

					&:after {
						content: " ";
						position: absolute;
						width: 100%;
						left: 0;
						bottom: 15px;
						border-bottom: 1px solid $color-text;
					}
				}
			}
		}
	}

	@if $device == all or $device == mobile {
		.b-search_results-refinements {
			@include respond-to(tablet) {
				width: 25%;
			}

			@include respond-to(phone) {
				width: 50%;
			}
		}

		.refinement {
			@include respond-to(not-desktop) {
				float: none;
			}

			&-wrapper {
				@include respond-to(not-desktop) {
					position: absolute;
					z-index: $z-min;
					top: 100%; left: 0;
				}

				@include respond-to(tablet) {
					width: 400%;
				}

				@include respond-to(phone) {
					width: 200%;
				}
			}
		}
	}

	.b-toggle_grid {
		float: right;
		margin-left: 20px;
		@include font(0, 0);

		@include respond-to(not-desktop) {
			display: none;
		}
	}

	.b-toggle_grid-icon {
		@include icon-styles;
		display: inline-block;
		height: $toggle_grid-size;
		width: $toggle_grid-size;
		cursor: pointer;

		&:before {
			display: inline-block;
			vertical-align: top;
			font-size: $toggle_grid-size;
			line-height: 1;
		}

		+ .b-toggle_grid-icon {
			margin-left: 10px;
		}

		&.m-grid:before {
			content: icon-char($icon_grid);
		}

		&.m-list:before {
			content: icon-char($icon_list-empty);
		}

		.wide-tiles & {
			&.m-grid:before {
				content: icon-char($icon_grid-empty);
			}

			&.m-list:before {
				content: icon-char($icon_list);
			}
		}
	}

	.refinement {
		.toggle {
			@extend %refinements-button;

			@include respond-to(not-desktop) {
				display: block;
			}
		}

		a {
			@include plp-refinements-link;
		}

		.selected a:before,
		.refinement-link.active:before {
			background: $color-black2;
		}

		.clear-refinement {
			font-size: $font_size-small;
			font-weight: 700;
			text-transform: uppercase;

			a:before {
				display: none;
			}
		}

		.unselectable {
			opacity: .5;
		}
	}

	.b-sort_by {
		position: relative;

		.sort-Go-Button {
			display: none;
		}

		@include respond-to(not-desktop) {
			float: right;
			width: auto;
		}
	}

	.b-filter_by {
		&-button {
			@extend %refinements-button;
		}
	}

	.b-sort_by-button,
	.b-filter_by-button {
		@include respond-to(phone) {
			text-align: left;
		}
	}

	.b-active_filters {
		clear: both;
		border-top: 1px solid $color-grey_light2;
		margin-bottom: -1px;

		.b-content {
			padding: 0 $gutter-global;
		}
	}

	.breadcrumb-relax {
		@include icon(close,after);
		@include font(0, 0);
		text-decoration: none;
	}
}
