@include minicart;

.b2b-header-minicart {
	@include respond-to(desktop) {
		&:hover .b2b-utility_menu-link{
			color: $color-white;
		}
	}

	@include respond-to(tablet) {
		padding-right: 15px;
	}
}

.b2b-mini_cart {
	&-link {
		> .b2b-minicart-label {
			display: none;
		}

		.b2b-minicart-quantity {
			margin-left: 3px;
			@include font(13px, false);
			vertical-align: middle;
		}
	}

	&-content {
		color: $color-black;
		background-color: $color-grey_light4;

		::-webkit-scrollbar-track,
		::-webkit-scrollbar-corner {
			background: $color-grey_light4;
		}

		@include respond-to(desktop) {
			right: -$gutter-global;
		}
	}

	&-header {
		padding: 8px 20px 8px 0;
		color: $color-white;
		@include font($font_size-xs, $letter_spacing-medium);
		font-weight: 700;
		text-align: right;
		text-transform: uppercase;
		background-color: $color-black2;

		@include respond-to(not-desktop) {
			padding-right: 30px;
		}
	}

	&-products {
		line-height: 18px;

		@include respond-to(desktop) {
			margin: 10px 10px 0;
			padding: 10px;
		}

		@include respond-to(not-desktop) {
			margin: 15px 20px;
			padding: 0 10px;
		}
	}

	&-product {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px solid $color-grey_light;

		@include respond-to(not-desktop) {
			margin-top: 15px;
			padding-top: 15px;
		}

		&:first-child {
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
		}
	}

	&-image {
		width: 75px;
		margin: 2px 10px 0 0;
	}

	&-name {
		color: $color-black;
		font-weight: 600;
		margin-bottom: 10px;
	}

	&-pricing > span {
		font-weight: 600;
	}

	&-price-unadjusted > span,
	&-pricing > span,
	&-attributes {
		color: $color-grey2;
	}

	&-subtotals-unadjusted {
		color: $color-grey2;
	}

	&-totals {
		padding: 20px;
		border-top: 1px solid $color-black3;

		@include respond-to(not-desktop) {
			padding: 10px 30px 20px;
			border-top-color: $color-grey_light;
		}
	}

	&-slot .html-slot-container {
		margin-top: 10px;
		@include font($font_size-small, false);
		color: $color-grey;
	}
}
