.price-unadjusted,
.product-standard-price,
.price-standard {
	@include font(15px, false, $font_family);
}

.price-adjusted-total,
.price-total,
.price-sales {
	@include font(15px, false, $font_family);
}
