.b-cart {
	&-table {
		tr {
			border-top-width: 2px;

			&.m-first {
				border-top-width: 5px;

				@include respond-to(phone) {
					border-top-width: 15px;
				}
			}

			@include respond-to(phone) {
				position: relative;
			}
		}

		th,
		td {
			padding-right: 0;

			&:last-child {
				padding-right: 20px;
			}
		}

		td {
			@include respond-to(not-phone) {
				padding-top: 7px;
				padding-bottom: 7px;

				&.b-cart-cell_image,
				&.b-cart-cell_details {
					float: none;
				}

				&.b-cart-cell_delivery_options {
					float: none;
				}
			}
		}

		.b-cart-image {
			float: left;
			width: 100px;
			margin-right: 20px;

			@include respond-to(tablet) {
				float: none;
				width: 75px;
				margin-bottom: 5px;
			}
		}

		.b-cart-product_info {
			overflow: hidden;

			@include respond-to(not-phone) {
				margin-top: 10px;
			}

			@include respond-to(tablet) {
				overflow: visible;
			}
		}

		.b-order_product-attribute {
			@include respond-to(tablet) {
				clear: left;
				margin-top: 10px;
			}
		}

		th.b-cart-cell_delivery_options {
			@media screen and (max-width: $width-desktop) {
				display: table-cell;
			}
		}

		.b-cart-cell_details,
		.b-cart-cell_delivery_options {
			@include respond-to(phone) {
				margin-left: 0;
			}
		}

		.b-cell-size {
			@include respond-to(not-phone) {
				text-align: center;
			}
		}
	}

	&-cell_image {
		width: 120px;
	}

	&-cell_details {
		.attribute-size {
			display: none;
		}
	}

	&-cell_delivery_options {
		.product-availability-list {
			display: inline-block;
			margin: 0;
		}
	}

	&-cell_price,
	&-cell_total {
		width: 13%;
	}

	&-cell_remove {
		@include respond-to(phone) {
			position: absolute;
			top: 10px; right: 0;
		}
	}

	&-remove_button {
		background: none;
		color: $color-red;

		&:hover {
			color: $color-black3;
		}

		&:before {
			@include font(12px, false);
			font-weight: 700;
		}
	}

	&-order_totals {
		@include respond-to(tablet) {
			float: right;
		}
	}
}
