@mixin search-form-button {
  padding: 8px 0 0;
  color: $color-grey_light2;

  &:before {
    @include font(20px, false);
    line-height: 1;
  }
}

$form-width: 360px;
$button-icon: magnifier;

.b2b-search-form {
  form {
    display: inline-block;
    width: $form-width;
    max-width: 100%;
    text-align: left;
  }

  input {
    float: left;
    margin-right: -40px;
    @include search-form-input;
  }

  button {
    float: right;
    width: auto;
    border: 0;
    background: none;
    box-shadow: none;
    @include font(0, false);
    color: $color-text;
    @include icon($button-icon);
    @include search-form-button;
  }

  span.error {
    display: none !important;
  }
}

$suggestions-width: 900px;
$products-width: 65%;
#search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: $z-header;
  width: $suggestions-width;
  max-width: 100%;
  margin: 0 auto;
  background-color: $color-black-transparent;

  @include respond-to(desktop) {
    top: auto;
    background-color: $color-none;
  }
}

.b2b-suggestion {
  @include clearfix;
  padding: 35px 0 25px;
  border: 1px solid $color-black3;
  border-top: 0;
  background-color: fade-out($color-black, 0.1);
  text-align: center;

  &,
  a {
    color: $color-grey2;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &-header {
    margin-bottom: 40px;
  }

  &-title {
    margin-bottom: 15px;
    @include font(15px, 1px);
    line-height: 1;
    text-transform: uppercase;
  }

  &-phrase {
    display: inline-block;

    a {
      float: left;
      color: $color-white;
    }

    &:before {
      content: '\2018';
      float: left;
    }

    &:after {
      content: '\2019\0020\003F';
    }
  }

  &-products {
    @include font(0, 0);
    text-align: left;

    @include respond-to(not-phone) {
      display: inline-block;
      vertical-align: top;
      width: $products-width;
    }
  }

  &-product {
    @include font;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 20px 10px;
    line-height: 1;

    @include respond-to(phone) {
      width: 100%;
    }

    .product-link {
      text-decoration: none;
    }

    .product-image {
      float: left;
      width: 75px;
      margin-right: 10px;

      img {
        width: 100%;
      }
    }

    .product-details {
      overflow: hidden;
    }

    .product-price {
      margin-top: 10px;
    }
  }

  &-content {
    @include font(0, 0);
    text-align: left;

    @include respond-to(not-phone) {
      float: left;
      width: 100% - $products-width;
    }
  }

  &-group {
    padding: 0 20px 20px;
    @include font;
    line-height: 1;
  }

  &-subtitle {
    margin-bottom: 15px;
    text-transform: uppercase;
    color: $color-white;
  }

  &-item {
    margin-bottom: 10px;
  }
}

.b2b-header-search {
  text-align: center;
}

.b2b-search-form {
  .input-text {
    @include font(15px, false, $font_family);
    color: $color-grey_light2;
    border-color: $color-grey_light2;
  }
}

.b-suggestion {
  max-width: 960px;
  margin: 0 auto;
  border: 0;
  color: $color-white;
  background-color: $color-none;

  @include respond-to(desktop) {
    padding-top: 30px;
  }

  @include respond-to(not-phone) {
    padding-bottom: 120px;
  }

  @include respond-to(not-desktop) {
    padding-top: 0;
  }

  &-content {
    @include respond-to(not-phone) {
      float: right;
    }
  }

  &-header {
    margin-bottom: 55px;

    .b-suggestion-title {
      @extend %heading-2;
    }

    a {
      color: $color-white;
    }
  }

  &-title {
    + a {
      @include font(14px, $letter_spacing-medium);
      text-transform: uppercase;
    }
  }

  &-product {
    .product-name {
      font-weight: 600;
    }

    .product-name,
    .product-price {
      color: $color-white;
    }
  }

  &-subtitle {
    @include font(14px);
    font-weight: 600;
  }

  &-item {
    .hit {
      color: $color-white;
      font-weight: 600;
    }
  }

  &-phrase.m-hide-q:after {
    content: '\2019';
  }
}
