// Header
// 'b-' prefixes only

.b2b-site_header {
	width: 100%;
	background: $color-header;

	&-wrapper {
		position: relative;
		z-index: $z-header;
	}

	&-placeholder {
		background-color: $color-header;
	}

	.js-header_fixed & {
		position: fixed;
		top: 0; right: 0;

		&-wrapper {
			position: fixed;
			z-index: $z-header;
		}
	}

	@include respond-to(not-desktop) {
		@include flexbox();
		@include flex-column();

		&:before {
			content: none;
		}

		.js-header_minimized & {
			overflow: hidden;
		}
	}
}

.b2b-header-logo_wrapper {
	position: relative;
	border-bottom: 1px solid $color-black2;

	@include respond-to(phone) {
		@include flexbox;
		@include flex-column;
	}
}

.b2b-primary_logo {
	height: $logo-height;
	padding: 20px 0;
	text-align: center;

	a {
		// inline-block should be added to this link for all browsers and devices for correct minimizing of logo
		display: inline-block;
		height: 100%;

		@include respond-to(desktop) {
			min-width: 530px; // prevent logo stretching
		}

		// but not for IE
		.ie & {
			display: inline;
		}

		&:before {
			content: '';
			@extend %bg-philippplein;
			background-position: 30px 50%;
			background-size: 55px auto;
			position: absolute;
			top: 0; left: 0;
			height: 100%;
			width: 100px;
			max-height: $header-height;
		}
	}

	img {
		height: 100%;
	}

	@include respond-to(desktop) {
		width: 50%;
		min-width: 530px; // prevent logo stretching (small desktop)
		margin: 0 auto;
	}

	@include respond-to(not-desktop) {
		background: none;

		a {
			&:before {
				content: none;
			}
		}
	}

	@include respond-to(tablet) {
		position: absolute;
		top: 0;
		left: 25%; right: 25%;
		height: $utility_menu-height_tablet;
		padding: 22px;
		z-index: $z-min;
	}

	@include respond-to(phone) {
		height: $logo-height_mobile;
		padding: 23px 10px;
		@include order(1);
	}
}

.b2b-header-menu_toggle {
	@include respond-to(tablet) {
		float: left;
		margin-left: 15px;
	}
}

.b2b-header-promotion {
	display: block;
	background-color: $color-white;

	@include respond-to(not-desktop) {
		@include order(3);
	}
}

.b-promotion {
	padding: 7px 60px 8px;
	color: $color-grey2;
	@include font($font_size-xs, $letter_spacing-medium);
	font-weight: 700;
	line-height: 15px;
	text-transform: uppercase;
	background-color: $color-black2;

	@include respond-to(phone) {
		padding: 5px 30px;
	}

	&-close {
		display: none;
		position: absolute;
		top: 7px; right: 40px;
		color: $color-grey2;

		@include respond-to(phone) {
			top: 4px; right: 15px;

			&:before {
				@include font($font_size-small, false);
			}
		}
	}
}

.account_name {
	display: none;//flex
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	max-width: 30%;
	font-size: 12px;
	align-items: center;
	color: #999693;
	z-index: 10;
	@media screen and (max-width: 500px) {
		display: none;
	}
	@media only screen and (max-width: 767px) {
		height: auto;
		padding: 10px 15px;
	}
	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		left: 75px;
	}
	@media screen and (min-width: 1025px) {
		left: 0;
		padding-left: 20px;
	}
}
.skip-to-main-content {
  display: none;
}
body:not(.js-header_fixed) {
	.is_stuck {
		position: static !important;

		+ div {
			display: none !important;
		}
	}
}

@include respond-to(not-desktop) {
	.js-keyboard_opened {
		.b2b-site_header-wrapper,
		.b2b-site_header {
			position: relative;
		}
	}
}
