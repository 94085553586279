.b2b-header_tools {
	@include respond-to(desktop) {
		margin-right: $gutter-global;
		float: right;
		clear: right;
		z-index: $z-min + 1;
	}

	.b2b-utility_menu-link {
		@include font(0, 0);
	}
}

.b2b-header-site_settings {
	@include respond-to(desktop) {
		float: right;
		margin: 20px $gutter-global 5px 0;
	}

	@include respond-to(not-desktop) {
		display: none;
	}
}
