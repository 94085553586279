@mixin b-return_form-title {
	margin-bottom: 15px;
	@include font;
	font-weight: 600;
	text-transform: uppercase;
}

@mixin b-return_form(
	$radio-indent: 25px,
	$block-indent: 40px
) {
	.b-return {
		&-info {
			margin-bottom: $block-indent;
		}

		&-email {
			.b-return-value {
				margin-right: 10px;
			}

			.b-button {
				vertical-align: 3px;
				margin: 0;
			}

			.f-label {
				display: inline;
				margin: 0;
				font: inherit;
				color: $color-text;

				&:before {
					content: none;
				}
			}
		}

		&-email_new {
			@include respond-to(not-phone) {
				max-width: 320px;
			}

			.f-field {
				margin: 5px 0;
			}
		}

		&-cancel_new_email {
			float: right;

			&.b-button {
				text-decoration: none;
				text-transform: uppercase;
			}

			&:before {
				vertical-align: baseline;
				@include font(8px, false);
				color: $color-text;
			}
		}

		&-delivery_service {
			margin-top: $block-indent;

			> .f-field > .f-label {
				@include b-return_form-title;
			}
		}

		&-delivery_notice {
			margin-left: $radio-indent;
			font-weight: 400;
			color: $color-grey2;
		}

		&-shipping {
			max-width: 740px;
			margin-left: $radio-indent;

			> .f-field {
				margin: 5px 0 0;
			}

			.f-field-button {
				text-align: right;
				margin-bottom: 0;
			}

			.f-disable_shipping {
				.f-select, .f-textinput {
					pointer-events: none;
					opacity: 0.5;
				}
			}
		}

		&-form {
			margin-bottom: 20px;

			.b-order_table {
				&-cell {
					vertical-align: top;
				}
			}

			.warning-message {
				display: block;
				color: $color-red;
			}
		}

		&-details_form {
			.f-select {
				width: 100%;
			}

			.f-radio-group {
				margin: 15px 0;
			}
		}

		&-exchange {
			margin-bottom: 0;

			&-item {
				margin-bottom: 10px;
			}

			&-comment {
				display: block;
				margin-bottom: 10px;
			}

			&-label,
			&-dropdown,
			&-remove {
				display: inline-block;
				vertical-align: middle;
			}

			&-label {
				max-width: 40%;
				margin: 0 5px 0 0;
			}

			&-dropdown {
				max-width: 90px;
			}

			&-remove {
				padding: 0 0 0 10px;
				background: none;
				color: $color-text;
				cursor: pointer;

				&:hover {
					color: $color-grey;
				}

				&:before {
					@include font(10px, false);
				}

				&:disabled {
					opacity: 0.5;
					cursor: default;
				}
			}
		}

		&-comment {
			max-width: 740px;
			margin-top: 20px;
		}

		&-shipment_actions {
			@include respond-to(not-phone) {
				float: right;
			}

			@include respond-to(phone) {
				margin-bottom: 20px;
			}
		}

		&-actions,
		&-shipment_actions {
			.b-button {
				@include respond-to(phone) {
					width: 100%;
				}
			}
		}
	}

	.b-return_information {
		&-title {
			@include b-return_form-title;
		}

		&-item {
			@include font(13px, false);
			margin-bottom: 5px;

			@include respond-to(not-phone) {
				overflow: hidden;

				&.b-return-order_id,
				&.b-return-date {
					float: left;
					clear: left;
					width: 360px;
				}
			}
		}

		&-label {
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;

			&:after {
				content: ':';
			}
		}

		&-value {
			display: inline-block;
			vertical-align: top;
		}
	}

	.b-return_bank {
		margin-top: 20px;

		&-form {
			max-width: 740px;
		}
	}
}

.MultiFile-list {
	display: flex;

	@include respond-to(not-phone) {
		position: absolute;
		left: -20px;
		top: 0;
		transform: translateX(-100%);
	}
}

.MultiFile-remove {
	border: 1px solid #000;
	color: rgba(0, 0, 0, 0);
	font-size: 0;
	height: 16px;
	position: absolute;
	right: 0;
	top: 0;
	width: 16px;

	&::before,
	&::after {
		background: #000;
		content: "";
		display: block;
		height: 100%;
		left: 50%;
		position: absolute;
		right: 50%;
		width: 1px;
	}

	&::before {
		transform: translate(-50%, 0) rotate(-45deg);
	}

	&::after {
		transform: translate(-50%, 0) rotate(45deg);
	}
}

.MultiFile-wrap {
	position: relative;

	@include respond-to(phone) {
		order: 1;
	}
}

.MultiFile-label {
	height: 140px;
	position: relative;
	
	& > span {
		display: flex;
		font-size: 0;
		height: 100%;
		flex: 0;
	}

	& > .MultiFile-title {
		font-size: 10px;
		word-break: break-all;
	}

	.MultiFile-label {
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		height: 100%;
		margin-right: 20px;
		width: 100px;
	}
}

.MultiFile-preview {
	display: block;
	flex: 1;
	height: 100%;
	margin-bottom: 10px;
	object-fit: contain;
	object-position: top;
	width: 100%;
}

.MultiFile-applied {
	display: none;
}