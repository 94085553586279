.b2b-header-countries_selector,
.b2b-header-languages_selector {
	.b2b-utility_menu-link {
		color: $color-grey_light2;
		line-height: 1;

		&:hover,
		&.active {
			color: $color-white;
		}
	}

	.b2b-utility_menu-popup {
		left: 0;
		right: auto;
	}

	.b-dropdown {
		::-webkit-scrollbar-track,
		::-webkit-scrollbar-corner {
			background: $color-none;
		}

		&-link {
			color: $color-white;
		}

		&-button:after {
			padding-top: 3px;
			font-size: 4px;
			vertical-align: 2px;
		}
	}

	@include respond-to(not-desktop) {
		.b-dropdown-header {
			line-height: $navigation_item-height_mobile;
		}

		.b2b-utility_menu-link {
			padding: 0;
			color: $color-black;
			line-height: $navigation_item-height_mobile;

			&:before {
				@include font(30px);
				border: 0;
			}
		}
	}

	@include respond-to(phone) {
		&:before {
			content: none;
		}
	}
}

.b2b-header-languages_selector {
	min-width: 90px;
}

.b2b-header-countries_selector {
	min-width: 145px;

	.flag-icon:before {
		content: none;
	}
}

@include b-dropdown;

@include b-dropdown-select;

.b-choose_country {
	max-width: 360px;
	margin: 0 auto;
	padding: 80px 0 10px;
	@extend %bg-world;
	background-position: 50% 0;
	text-align: center;

	&-title {
		@extend %heading-3;
		margin-bottom: 30px;
	}

	&-message {
		margin-top: 30px;
		@include font($font_size-small, false);
		color: $color-grey;
	}

	&-select {
		display: inline-block;
		max-width: 100%;
		margin-top: 15px;

		.f-select {
			width: auto;
			padding-left: 40px;
			padding-right: 40px;
			border-color: $color-button_border_light;
			@include font(13px, false);
			text-transform: uppercase;
		}
	}

	&-button,
	&-select .f-select {
		@include respond-to(phone) {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}

.dialog-choose_country {
	overflow: visible;
	max-width: 520px;
}
