// Hide elements
.h-hidden {
	display: none !important; // !important needed because of use this class to override inline styles

	&_desktop {
		@include respond-to(desktop) {
			display: none !important; // !important needed because of use this class to override inline styles
		}
	}

	&_tablet {
		@include respond-to(tablet) {
			display: none !important; // !important needed because of use this class to override inline styles
		}
	}

	&_phone {
		@include respond-to(phone) {
			display: none !important; // !important needed because of use this class to override inline styles
		}
	}
}

.hidden {
	display: none;
}
.desktop-hidden {
    display: none;
    @include respond-to(phone) {
        display: block;
    }
}
.mobile-hidden {
    display: block;
    @include respond-to(phone) {
        display: none;
    }
}
