@include checkout-base;
$ch-gutter: $gutter-global;

%checkout-primary {
	@extend %b-primary;
	
	@include respond-to(desktop) {
		float: left;
		width: $primary-width_checkout;
	}
	overflow: visible;
}

%checkout-primary-block {
	width: 100%;
	margin-bottom: $gutter-global;
	
	@include respond-to(not-phone) {
		padding: $gutter-global;
		background-color: $color-white;
	}
}

%checkout-secondary {
	@include respond-to(desktop) {
		float: right;
		width: $secondary-width_checkout;
		max-width: strip-unit($secondary-width_checkout) + vw; //to prevent extending coupon code block when long words are used
	}
	
	@include respond-to(phone) {
		margin-top: $gutter-global;
		max-width: 100vw;
	}
}

%checkout-secondary-block {
	margin-bottom: 5px;
	padding: 20px;
	background-color: $color-grey_light3;
	
	&:last-child {
		margin-bottom: 0;
	}
}

%checkout-secondary-title {
	@extend %heading-3;
}

.b-checkout-primary {
	@extend %checkout-primary;
	
	.f-form-warning {
		@include respond-to(not-phone) {
			float: left;
		}
	}
	
	.f-field-checkbox {
		margin: 15px 0 0;
	}
	
	.privacy-policy {
		display: inline-block;
		margin-top: 10px;
	}
}

.b-checkout-title {
	@extend %heading-3;
	
	@include respond-to(phone) {
		text-align: center;
	}
}

.b-checkout-subtitle {
	@extend %heading-3;
	@include font($font_size, false);
}

.b-checkout-address {
	@extend %checkout-primary-block;
}

.b-checkout-actions {
	margin: 0;
	
	@include respond-to(phone) {
		text-align: center;
		
		.b-button {
			width: 100%;
			max-width: 320px;
		}
	}
}
