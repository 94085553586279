// Mixins
// CLEARFIX
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}


// LINKS
// Links styles: :link, :visited, :hover, :active (can accept 2-8 values, link style is default for visited, hover style is default for active)
@mixin links($l-color, $l-decor, $h-color: $l-color, $h-decor: $l-decor, $v-color: $l-color, $v-decor: $l-decor, $a-color: $h-color, $a-decor: $h-decor ) {
  &:link {
    color: $l-color;
    text-decoration: $l-decor;
  }

  &:visited {
    color: $v-color;
    text-decoration: $v-decor;
  }

  &:hover {
    color: $h-color;
    text-decoration: $h-decor;
  }

  &:active {
    color: $a-color;
    text-decoration: $a-decor;
  }
}


// BACKGROUND IMAGE
@mixin bg-img($bg-img, $bg-pos: 0 0, $bg-repeat: no-repeat) {
  background: url("../images/" + $bg-img ) $bg-pos $bg-repeat;
}


// TRANSITION
// Example: @include transition( width, 1s, ease-out, 0.5s);
// Parameters: ease, ease-in, ease-out, ease-in-out, linear, steps, cubic-bezier .
@mixin transition($transition-property: all, $transition-duration: 0.2s, $transition-timing-function: ease-in, $transition-delay: 0s) {
  -webkit-transition: $transition-property $transition-duration $transition-timing-function $transition-delay;
  transition: $transition-property $transition-duration $transition-timing-function $transition-delay;
}


// FONTS
// Example: @include font(16px, 0.5px, 'Noto Sans');
@mixin font($mx-font-size: $font_size, $mx-letter-spacing: $letter_spacing, $mx-font-family: false) {
  @if $mx-font-family {
    font-family: $mx-font-family;
  }

  $font_size-rem: strip-unit($mx-font-size) / strip-unit($font_size);
  font-size: $mx-font-size;
  font-size: #{$font_size-rem}rem;

  @if $mx-letter-spacing {
    letter-spacing: $mx-letter-spacing;
  }
}

@function strip-unit($number) {
  @if type-of($number)=='number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin text-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// GRADIENT
@mixin linear-gradient($mx-gradient-direction: right, $mx-gradient-color1: $color-white, $mx-gradient-color2: $color-black, $mx-background-color: false) {
  $mx-gradient-direction2: $mx-gradient-direction;

  @if $mx-gradient-direction ==right {
    $mx-gradient-direction2: left;
  }

  @elseif $mx-gradient-direction ==left {
    $mx-gradient-direction2: right;
  }

  @elseif $mx-gradient-direction ==top {
    $mx-gradient-direction2: bottom;
  }

  @elseif $mx-gradient-direction ==bottom {
    $mx-gradient-direction2: top;
  }

  @if $mx-background-color {
    background-color: $mx-background-color;
  }

  background-image: -moz-linear-gradient($mx-gradient-direction2, $mx-gradient-color1 0%, $mx-gradient-color2 100%);
  background-image: -webkit-linear-gradient($mx-gradient-direction2, $mx-gradient-color1 0%, $mx-gradient-color2 100%);
  background-image: linear-gradient(to $mx-gradient-direction, $mx-gradient-color1 0%, $mx-gradient-color2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$mx-gradient-color1}', endColorstr='#{$mx-gradient-color2}', GradientType=1);
}


// GRAYSCALE
// on, off
@mixin grayscale($mx-grayscale: on) {
  @if $mx-grayscale ==on {
    -webkit-filter: grayscale(80%);
    filter: grayscale(80%);
    filter: gray;
    /*filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.9999 0.9999 0.9999 0 0 0.9999 0.9999 0.9999 0 0 0.9999 0.9999 0.9999 0 0 0 0 0 1 0' /></filter></svg>#greyscale");*/
  }

  @else {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    filter: none;
  }
}


// ABSOLUTE CENTER ALIGNMENT
@mixin abs-center($mx-left: 50%, $mx-top: 50%, $mx-translate-x: -50%, $mx-translate-y: -50%) {
  left: $mx-left;
  position: absolute;
  top: $mx-top;
  @include transform(translate($mx-translate-x, $mx-translate-y));
}


// CALC
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}


// INPUT PLACEHOLDER
@mixin placeholder {
  ::-webkit-input-placeholder {
    @content
  }

  :-moz-placeholder {
    @content
  }

  ::-moz-placeholder {
    @content
  }

  :-ms-input-placeholder {
    @content
  }
}


// CSS3 TRANSFORM
@mixin transform($string) {
  -webkit-transform: $string;
  -ms-transform: $string;
  transform: $string;
}


// USER SELECT
@mixin user-select($value) {
  -webkit-user-select: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}


// CUSTOM SCROLLBAR
@mixin scrollbar($bg-color: $color-grey_light2, $th-color: $color-grey_light) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: none;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner {
    background: $bg-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $th-color;
    -webkit-border-radius: 8px;
  }
}

//ANIMATED HOVERS
@mixin block-animated-border($br-color: $color-white, $br-gutter: 5px) {

  &:before,
  &:after {
    @include transition($transition-duration: 0.5s, $transition-timing-function: ease);

    content: '';
    position: absolute;
  }

  &:before {
    top: $br-gutter;
    bottom: $br-gutter;
    right: 50%;
    left: 50%;
    border-top: 1px solid $color-none;
    border-bottom: 1px solid $color-none;
  }

  &:after {
    top: 50%;
    bottom: 50%;
    right: $br-gutter;
    left: $br-gutter;
    border-right: 1px solid $color-none;
    border-left: 1px solid $color-none;
  }

  &:hover {

    &:before,
    &:after {
      border-color: $br-color;
    }

    &:before {
      left: $br-gutter;
      right: $br-gutter;
    }

    &:after {
      top: $br-gutter;
      bottom: $br-gutter;
    }
  }
}


// ANIMATION
@mixin animation($string) {
  -webkit-animation: #{$string};
  animation: #{$string};
}


// KEYFRAMES
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content
  }

  @keyframes #{$name} {
    @content
  }
}

//Rotation

@mixin rotation() {
  @include animation(rotate 2s linear infinite);

  @include keyframes(rotate) {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}


//Color invertion for base64

@mixin invert() {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

@mixin box-shadow($string) {
  -webkit-box-shadow: #{$string};
  box-shadow: #{$string};
}

@mixin columns($value, $gap: 0) {
  -webkit-columns: $value;
  -moz-columns: $value;
  columns: $value;

  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}


// Least common multiple of two numbers
@function lcm($x, $y) {
  @return round(($x * $y) / gcd($x, $y));
}


// Greatest common divisor of two numbers
@function gcd($x, $y) {
  @while $y >0 {
    $t: $y;
    $y: $x % $t;
    $x: $t;
  }

  @return $x;
}


// Optimise product grid by hiding last not full line
@mixin optimized-grid($grid-size: 6,
  $grid-count: 12,
  $grid-list: 'ul',
  $grid-item: '.grid-tile'

) {
  $grid-rest: $grid-count % $grid-size;

  @if ($grid-rest > 0) {
    $grid-iteration: lcm($grid-rest, $grid-size) / $grid-rest;
    $grid-selector: '';

    @for $i from 1 through $grid-iteration - 1 {
      $iteration-list: '#{$grid-list}:nth-of-type(#{$grid-iteration}n + #{$i}):last-of-type:not(:last-child)';
      $iteration-rest: $i * $grid-count % $grid-size;

      @for $j from 1 through $iteration-rest {
        $grid-selector: $grid-selector + $iteration-list + ' #{$grid-item}:nth-last-child(#{$j}),
 ';

      }
    }

    #{$grid-selector} .to_avoid_removing_last_comma {
      display: none;
      @content;
    }
  }
}

// PDP tags/labels (gender, etc)
@mixin pdp-tags {
  margin: 10px 0 7px;

  .b-pdp-tags__list {
    display: flex;
    flex-wrap: wrap;
  }

  .b-pdp-tags__item {
    flex: 0 0 auto;
    margin: 0 2px 4px;
    background: #000;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 7px;
    text-transform: uppercase;
  }
}

// Quick view link on PLP tile, sits in .b-product_tile
@mixin quick-view-link {
  &-quickview {
    display: none;
    padding: 8px 20px;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    background-color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: $color-black;
    white-space: nowrap;
    text-decoration: none;

    .icon-minibag:before {
      margin-top: -5px;
    }

    .b-product_tile:hover & {
      display: block;
    }
  }
}

@mixin plp-carousel {
  .b-product_carousel {
    margin: 0 auto;
    max-width: 1600px;
    padding: 0 50px;
    @include respond-to(max-tablet){
      padding: 0;
    }
    .grid-tile {
      max-width: 140px;
      padding: 0 !important;
      .b-product_tile {
        margin: 0;
      }
    }
  }
}

// Crypto icon animation
@mixin rotate-crypto-icon {
  @-webkit-keyframes rotate-icon {
    0% {
      -webkit-transform: rotateY(0deg);
      background-image: url("../images/crypto-icon-btc.svg");
    }

    16% {
      background-image: url("../images/crypto-icon-cent.svg");
    }

    33% {
      -webkit-transform: rotateY(360deg);
      background-image: url("../images/crypto-icon-cent.svg");
    }

    49% {
      background-image: url("../images/crypto-icon-eth.svg");
    }

    66% {
      -webkit-transform: rotateY(0deg);
      background-image: url("../images/crypto-icon-eth.svg");
    }

    82% {
      background-image: url("../images/crypto-icon-btc.svg");
    }

    100% {
      -webkit-transform: rotateY(360deg);
      background-image: url("../images/crypto-icon-btc.svg");
    }
  }

  @keyframes rotate-icon {
    0% {
      transform: rotateY(0deg);
      background-image: url("../images/crypto-icon-btc.svg");
    }

    16% {
      background-image: url("../images/crypto-icon-cent.svg");
    }

    33% {
      transform: rotateY(360deg);
      background-image: url("../images/crypto-icon-cent.svg");
    }

    49% {
      background-image: url("../images/crypto-icon-eth.svg");
    }

    66% {
      transform: rotateY(0deg);
      background-image: url("../images/crypto-icon-eth.svg");
    }

    82% {
      background-image: url("../images/crypto-icon-btc.svg");
    }

    100% {
      transform: rotateY(360deg);
      background-image: url("../images/crypto-icon-btc.svg");
    }
  }
}
