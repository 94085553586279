@mixin plp-product_tile(
  $tile-ratio: 1.5,
  $tile-ratio_product: 1.5,
  $tile-ratio_wide: false
) {
  .grid-tile {
    display: inline-block;
    vertical-align: top;
    width: percentage(1 / $grid-size);
    @include font;
    
    .wide-tiles & {
      width: percentage(1 / $grid-size_wide);
    }
    
    &.plp-wide-slot-hidden {
      display: none;
    }
    
    .b-banner.m-product:before,
    &:before {
      content: '';
      float: left;
    }
    
    &:before {
      padding-bottom: 100% * $tile-ratio;
      
      @if ($tile-ratio_wide) {
        .wide-tiles & {
          padding-bottom: 100% * $tile-ratio_wide;
        }
      }
    }
    
    .b-banner.m-product:before {
      padding-bottom: 100% * $tile-ratio_product;
    }
    
    .outfit-view & {
      .js-rollover-view,
      .js-outfit-view ~ .js-product-view {
        display: none !important;
      }
      
      .js-outfit-view {
        display: block;
      }
    }
    
    @include respond-to(tablet) {
      width: percentage(1 / $grid-size_tablet);
    }
    
    @include respond-to(phone) {
      width: percentage(1 / $grid-size_mobile);
    }
  }
  
  @include respond-to(desktop) {
    .js-images-loaded .product-image:hover {
      .js-rollover-view {
        display: block;
        
        ~ .js-product-view {
          display: none;
        }
      }
    }
  }
  
  .grid-banner {
    position: relative;
    overflow: hidden;
    
    &.grid-banner-2x1,
    &.grid-banner-2x2 {
      width: percentage(2 / $grid-size);
      margin-bottom: -1px;
      
      .wide-tiles & {
        width: percentage(2 / $grid-size_wide);
      }
      
      @include respond-to(tablet) {
        width: percentage(2 / $grid-size_tablet);
      }
      
      @include respond-to(phone) {
        width: percentage(2 / $grid-size_mobile);
      }
    }
    
    &.grid-banner-1x2 {
      &:before {
        padding-bottom: 200% * $tile-ratio;
        
        @if ($tile-ratio_wide) {
          .wide-tiles & {
            padding-bottom: 200% * $tile-ratio_wide;
          }
        }
      }
    }
    
    &.grid-banner-2x1 {
      &:before {
        padding-bottom: 50% * $tile-ratio;
        
        @if ($tile-ratio_wide) {
          .wide-tiles & {
            padding-bottom: 50% * $tile-ratio_wide;
          }
        }
      }
    }
    
    &.grid-banner-3x1 {
      width: percentage(3 / $grid-size);
      margin-bottom: -1px;
      
      .wide-tiles & {
        width: percentage(3 / $grid-size_wide);
      }
      
      &:before {
        padding-bottom: percentage(1/3) * $tile-ratio;
        
        @if ($tile-ratio_wide) {
          .wide-tiles & {
            padding-bottom: percentage(1/3) * $tile-ratio_wide;
          }
        }
      }
      
      @include respond-to(tablet) {
        width: percentage(3 / $grid-size_tablet);
      }
      
      @include respond-to(phone) {
        width: percentage(2 / $grid-size_mobile);
      }
    }
    
    .b-banner {
      width: 100%;
    }
  }
  
  .tiles-container {
    @include font(0, 0);
  }
  
  .b-product_tile {
    margin: 0 $gutter-global 20px;
    position: relative;
    text-align: center;
    
    @include respond-to(phone) {
      margin-left: 10px;
      margin-right: 10px;
    }
    
    &-image {
      position: relative;
      overflow: hidden;
      margin: 0 0 10px;
      
      &-container {
        &-desktop {
          @include respond-to(phone) {
            display: none;
          }
        }
        
        &-mobile {
          @include respond-to(not-phone) {
            display: none;
          }
        }
      }
      
      img {
        width: 100%;
      }
      
      // to prevent content jumping while loading image of product
      &:before {
        content: '';
        float: left;
        padding-bottom: percentage(560/440); // image on PLP has resolution 440x560
      }
    }
    
    &-image_link {
      display: block;
    }
    
    &-name_link {
      text-decoration: none;
    }
    
    &-pricing {
      > span {
        display: block;
      }
    }
    &-wishlist {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $color-black;
      cursor: pointer;
      z-index: 3;
      
      &.icon-star.active {
        &:before {
          content: "\f16b";
        }
      }
      
      span {
        display: none;
      }
    }
    &-action {
      display: none;
      left: 0;
      right: 0;
      bottom: 25px;
      margin: 0 auto;
      position: absolute;
      width: 300px;
      max-width: 100%;
    }
    
    @include respond-to(desktop) {
      &:hover &-action {
        display: block;
      }
    }
    
    &-swatches-list {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
    
    &-size_list {
      display: none;
      margin: 0 -12px;
      
      &.selected {
        display: block;
      }
    }
    
    &-size {
      position: relative;
      display: inline-block;
      margin: 0 6px;
      
      &.m-not_available {
        color: $color-grey2;
        
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 24px;
          transform: rotate(-45deg);
          margin-left: -12px;
          border-top: 1px solid $color-grey_light;
        }
      }
    }
    
    &-link {
      display: none;
    }
    
    // Product custom.label over tile img, 
    // visual style is different across sites
    &-labels_wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      z-index: 2;
      .b-product_tile:hover & {
        display: none
      }
    }
    &-gender {
      display: none; // tmp
      padding: 3px 5px;
      background: #000;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px;
      border-radius: 3px;
    }
    
    &-label, &-promo {
      display: inline-block;
      padding: 5px 8px;
      margin: 5px 0;
      line-height: 1;
      text-transform: uppercase;
    }
    // Promo name under tile price
    &-promotions_wrapper {
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .callout-message {
        display: block;
        margin-bottom: 4px;
        font-size: 11px;
        line-height: 1;
        font-weight: 600;
        color: $color-red;
      }
    }
    
    // Centered over img, // may be multiple <span>s
    %centered-tile-label {
      padding: 6px 12px;
      font-weight: 600;
      font-size: 11px;
      text-align: center;
      background-color: rgba(255, 255, 255, .45);
      border-radius: 3px;
      text-transform: uppercase;
      white-space: nowrap;
    }
    // Second label (i.e. "look at my back")
    &-custom_label {
      position: absolute;
      top: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 4;
      @extend %centered-tile-label;
      & span {
        display: block;
        margin-bottom: 5px;
        @extend %centered-tile-label;
      }
      &:has(> span) {
        background-color: transparent !important;
        padding: 0;
      }
      .b-product_tile:hover & {
        display: none
      }
    }

  }
  // b-product_tile
  
  // Crypto tooltip
  .crypto-note {
    display: none;
    max-width: 80px;
    background-color: #000;
    padding: 1px 4px;
    position: relative;
    margin-top: 5px;
    cursor: help;
    border-radius: 3px;
    @include respond-to(desktop) {
      min-width: auto;
      display: inline-block;
      margin-left: 5px;
    }
    &:hover > &__tooltip {
      opacity: 1;
      visibility: visible;
      z-index: 99;
    }
    &__tooltip {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      position: absolute;
      width: 175px;
      top: -100%;
      left: 50%;
      transform: translateX(-23%) translateY(-91%);
      padding: 15px;
      border-radius: 5px;
      background-color: #fff;
      font-size: 12px;
      font-weight: normal;
      text-transform: none;
      color: #000;
      box-shadow: 0 1px 15px -9px rgba(0, 0, 0, .7);
      transition: opacity .2s ease-in;
      @include respond-to(tablet) {
        width: 240px;
        transform: translateX(-17%) translateY(-91%);
      }
      @include respond-to(desktop) {
        width: 240px;
        font-size: 11px;
        transform: translateX(-50%) translateY(-91%);
        &:before {
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: -9px;
        left: 35px;
        border-top: 10px solid #fff;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        z-index: 80;
      }
    }
    &__label {
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 10px;
      font-weight: 400;
      color: #fff;
      @include respond-to(desktop) {
        font-size: 9px;
      }
    }
  }
}
