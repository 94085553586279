@mixin shopping_cart-base (
    $secondary-blocks-gutter: 20px
) {
    .pt_cart {
        .error-form {
            padding: 0 $gutter-global;
        }
    }

    .b-cart {
        // Cart title
        &-actions {
            @include clearfix;

            &.b-title_box {
                @include respond-to(desktop) {
                    + .b-content {
                        margin-top: 40px;
                    }
                }

                @include respond-to(not-desktop) {
                    margin-bottom: 30px;
                }
            }
        }

        &-action {
            &-continue_shopping {
                float: left;

                @include respond-to(tablet) {
                    .b-cart-actions.m-bottom & {
                        display: none;
                    }
                }

                @include respond-to(phone) {
                    display: none;
                }
            }

            &-checkout {
                float: right;

                @include respond-to(not-desktop) {
                    .b-cart-actions.m-bottom & {
                        float: none;
                        margin: 0;
                        text-align: center;

                        .b-button {
                            width: 100%;
                            max-width: 320px;
                        }
                    }
                }

                @include respond-to(phone) {
                    .b-cart-actions.b-title_box & {
                        display: none;
                    }
                }
            }


        }

        &-checkout_consent {
            margin-top: 15px;
            color: $color-grey;
            a {
                color: $color-grey;
            }
        }

        &-empty_content {
            margin: 150px $gutter-global;

            @include respond-to(not-desktop) {
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }

        // Secondary blocks
        @if $secondary-blocks-gutter {
            &-totals_actions {
                margin-bottom: $secondary-blocks-gutter;
            }
        &-checkout_consent {
            margin: 15px 0 0;
            color: $color-grey;
            font-size: 11px;
            a {
                color: $color-grey;
            }
        }

            @include respond-to(tablet) {
                &-totals {
                    @include flexbox;
                    @include row-wrap;
                }

                &-totals_actions {
                    width: 40%;
                    max-width: 40vw; //to prevent extending coupon code block when long words are used
                    margin-bottom: 0;
                }

                &-order_totals {
                    @include flexbox-inline;
                    @include flex-column;
                    @include jc-space-between;
                    width: calc(60% - #{$secondary-blocks-gutter});
                    margin-left: $secondary-blocks-gutter;
                }
            }
        }
    }

    .googlepay-messages {
        margin: 15px 20px;
        .alert-danger,
        .alert-success {
            padding: 15px;
            font-size: 14px;
        }
        .alert-danger {
            background-color: #FFDBDB;
        }
        .alert-success {
            background-color: #EAFFDD;
        }
    }

    .gpay-card-info-container-fill {
      height: 45px;
    }

    .gpay-card-info-container {
      max-width: 100%;
      min-width: auto !important;
    }

    .order-totals-table {
        line-height: 1.5;

        tr {
            border: 0;
        }

        td {
            padding: 3px 0;
            text-align: right;
            white-space: nowrap;

            &:first-child {
                text-align: left;
                white-space: normal;
            }
        }

        .discount {
            color: $color-red;
        }

        .order-subtotal,
        .order-total {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    // Customer Care
    .b-customer_care {
        @include flexbox;
        margin-top: 50px;
        border-top: 1px solid $color-grey_light;
        text-align: center;

        @include respond-to(phone) {
            @include wrap;
        }

        &-item {
            @include flex(1);
            padding: 30px;

            @include respond-to(phone) {
                @include flex(50%);
                padding: 20px 30px;
            }
        }

        &-link {
            display: inline-block;
            vertical-align: top;
            text-decoration: none;

            &:before {
                display: block;
                margin-bottom: 5px;
                @include font(25px, false);
            }
        }
    }
}

@mixin shopping_cart-table {
    .b-cart {
        &-cell_delivery_options {
            line-height: 1;

            .home-delivery {
                margin-bottom: 0;
            }

            .product-availability-list {
                margin-top: 10px;
            }
        }

        &-customization-unavailable {
            margin-top: 10px;
            @include font($font_size-small, false);
            line-height: 1.2;
            text-transform: uppercase;
            color: $color-red;
        }

        &-cell_in_wishlist {
            @include font(11px, false);
        }

        &-cell_price,
        &-cell_total {
            width: 15%;
        }

        &-table {
            .price-unadjusted .label,
            .price-adjusted-total .label {
                display: none;
            }

            .price-total,
            .price-sales {
                display: block;
            }

            .price-unadjusted,
            .price-standard,
            .price-promotion:after,
            .promo-adjustment {
                display: block;
                line-height: 14px;
            }

            .price-unadjusted,
            .price-standard {
                margin-bottom: 5px;
            }

            .price-promotion:after,
            .promo-adjustment {
                margin-top: 5px;
                color: $color-red;
            }

            .price-promotion:after {
                @include respond-to(not-phone) {
                    content: '\00A0';
                }
            }
        }

        // Coupons and Promos
        &-coupon {
            .label {
                display: none;
            }
        }

        &-discount {
            display: none;
        }

        &-cell_applied {
            .bonus-item {
                display: inline-block;
                padding: 4px 12px; //check 4px 12px 2px
                background-color: $color-green;
                text-transform: uppercase;
                color: $color-white;

                &.m-notapplied {
                    background-color: $color-red;
                }
            }
        }

        &-promo {
            .b-order_table-value {
                color: $color-red;
            }

            .b-order_table-label {
                @include respond-to(not-phone) {
                    margin-right: 10px;
                }

                @include respond-to(phone) {
                    width: auto;
                }
            }
        }

        // Bundle
        &-bundle {
            @include font($font_size-small, false);

            td {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .b-cart-cell_image {
                text-align: right;
            }

            .b-cart-image {
                width: 65px;
            }

            .b-cart-cell_details .b-order_product-name {
                @include font($font_size-small, false);
            }

            @include respond-to(phone) {
                .b-cart-cell_image {
                    float: left;
                    text-align: left;
                }

                .b-cart-cell_price,
                .b-cart-cell_total {
                    display: none;
                }
            }
        }
    }

    @include respond-to(not-phone) {
        .b-cart-table {
            .ui-spinner {
                max-height: 40px;
            }
        }
    }

   @media screen and (max-width: $width-tablet) {
        th.b-cart-cell_delivery_options {
            display: none;
        }

        td.b-cart-cell_delivery_options {
            margin-top: -10px;
            padding-top: 0;
        }
    }
}

.b-cart-cell_duties {
    @include respond-to(phone) {
        position: relative;
        z-index: 1;
    }
}

.b-cart-cell_duties-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.order-duties {
    $selector: &;
    line-height: 1.5;
    position: relative;

    .b-account & {
        display: none;
    }

    .b-checkout-secondary & {
        z-index: 1;

        @include respond-to(phone) {
            z-index: 4;
        }
    }

    &-title {
        cursor: pointer;
        display: inline-block;
        color: $color-grey;
        font-size: 10px;
        font-weight: 600;
        margin: 5px 0;
        text-transform: uppercase;

        .icon {
            display: inline;
            &:before {
                font-size: 12px;
                @include respond-to(phone) {
                    font-size: 15px;
                }
            }
        }
        &-container {
            display: inline;

            .content-asset {
                display: inline-block;
            }
        }
    }// &-title

    &-content {
        @include abs-center(-135px);
        background-color: $color-grey_light3;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba($color-black, 0.15);
        display: none;
        font-size: 12px;
        padding: 20px 15px;
        text-transform: none;
        width: 250px;

        &::before {
            background-color: inherit;
            box-shadow: 2px -2px 2px rgba($color-black, 0.075);
            content: '';
            height: 14px;
            position: absolute;
            right: -7px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            width: 14px;
        }

        @include respond-to(not-desktop) {
            left: 0;
            margin-top: 4px;
            top: 100%;
            transform: none;
            max-width: 350px;
            width: 100%;
            z-index: 4;

            &::before {
                box-shadow: -2px -2px 2px rgba($color-black, 0.075);
                left: 50%;
                margin-top: 1px;
                top: 0;
                transform: translate(-50%, -50%) rotate(45deg);
            }

            .b-checkout-secondary & {
                bottom: 100%;
                left: 0;
                margin-top: 0;
                margin-bottom: 10px;
                top: auto;
                transform: none;
                width: 100%;
                max-width: 350px;

                &::before {
                    left: 50%;
                    bottom: 0;
                    box-shadow: 2px 2px 2px rgba($color-black, 0.075);
                    margin-top: 0;
                    margin-bottom: 1px;
                    top: auto;
                    transform: translate(-50%, 50%) rotate(45deg);
                }
            }

            .b-cart-cell_total & {
                @include abs-center(-170px);

                margin-top: 0;
                width: 310px;

                &::before {
                    background-color: inherit;
                    box-shadow: 2px -2px 2px rgba($color-black, 0.075);
                    content: '';
                    height: 14px;
                    position: absolute;
                    left: auto;
                    right: -7px;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    width: 14px;
                }
            }
        }

        .content-asset {
            overflow: auto;
            max-height: 190px;
        }
    }// &-content

     &#{$selector}--not_mobile {
        display: none;

        @include respond-to(not-phone) {
            display: inline-block;
        }
    }
    &#{$selector}--mobile {
        display: none;
        position: relative;

        @include respond-to(phone) {
            display: inline-block;
        }

        & .order-duties-title {
            position: relative;
            -webkit-tap-highlight-color: transparent;
        }

        & .order-duties-content {
            left: -250px;
            min-width: 250px;
            top: 15px;
            transform: none;

            &::before {
                content: none;
            }
        }
    }// --mobile

}//.order-duties
