@include main-navigation;

@include respond-to(desktop-small) {
	.b2b-main_navigation {
		width: 50%;
	}
}

@include respond-to(desktop) {
	.b2b-main_navigation {
		margin: 0 auto;
		width: 65%;
	}

	.b2b-navigation {
		position: static;
		text-align: center;

		&-item,
		&-banner {
			margin-top: 15px;
		}

		&-link {
			text-transform: uppercase;
		}

		&-top_link {
			min-height: $navigation-height;
			@include font($font_size-small, 2px);
			color: $color-white;
			text-transform: uppercase;
		}

		&-top_item {
			margin: 0 15px;
			&:hover &-top_link {
				-webkit-text-stroke: 1px $color-white;
				// fallback for IE
				@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
					text-shadow: 0 0 1px $color-white;
				}
				& .b2b-navigation-dropdown {
					padding-bottom: 30px;
				}
			}
		}



		&-top_item:hover &-top_link.has-sub-menu:after {
			content: '';
			position: absolute;
			bottom: 0; left: 50%;
			width: 0; height: 0;
			$menu-arrow-size: 8px;
			margin-left: -$menu-arrow-size;
			border-width: 0 $menu-arrow-size $menu-arrow-size;
			border-style: solid;
			border-color: $color-grey_light4 transparent;
		}

		.js-header-flyout_opened &-top_item:hover{
			.b2b-navigation-top_link.has-sub-menu:after {
				content: none;
			}

			.b-navigation-dropdown {
				display: none;
			}
		}

		&-dropdown {
			border: none;
			border-bottom: 1px solid $color-grey_light3;
			background-color: $color-grey_light4;
			z-index: $z-min;
			top: auto;
			padding-bottom: 30px;
		}

		&-menu.b-navigation-level_2 {
			margin-top: 10px;
			padding: 0 50px 20px;
		}

		&-level_2.m-horizontal > &-item {
			width: 185px;
			padding: 0 15px;
		}

		&-banner {
			float: right;
			margin-top: 0;

			.banner-item {
				position: relative;
				text-align: center;
			}

			.banner-info {
				position: absolute;
				left: 0; bottom: 0;
				width: 100%;
				padding-bottom: 20px;
			}

			.banner-title {
				margin-bottom: 20px;
				@include font($font_size-h2, $letter_spacing-medium, $font_family-additional);
				color: $color-white;
				text-transform: uppercase;
			}

			.banner-link {
				@include transition();
				display: inline-block;
				padding: 8px 20px;
				@include font(15px, false);
				font-weight: 600;
				color: $color-black;
				text-transform: uppercase;
				text-decoration: none;
				background-color: $color-white;
				border: 1px solid $color-white;

				&:hover {
					background-color: $color-none;
					color: $color-white;
				}
			}
		}

		&-link &-text:hover {
			text-decoration: underline;
			color: $color-grey;
		}

		&-link.m-level_2 {
			color: $color-black;
			font-weight: 600;
			letter-spacing: $letter_spacing-medium;
		}

		&-link.m-level_3,
		&-view_all &-link {
			@include font($font_size-small, $letter_spacing-medium);
			font-weight: 400;
			color: $color-grey;
		}
	}
}

@include respond-to(not-desktop) {
	.b2b-main_navigation {
		z-index: $z-min;
	}

	.b2b-navigation {
		&-link {
			color: $color-grey2;
			text-transform: uppercase;
		}

		&-bottom_item {
			width: 50%;

			&:before {
				content: none;
			}

			&:first-child {
				position: relative;
				border: 0;

				&:after {
					content: '';
					position: absolute;
					top: 20px; bottom: 20px;
					right: 0;
					width: 1px;
					background-color: $color-black3;
				}
			}

			.b-shipto {
				position: relative;
				top: 20px;
				display: block;
				padding-left: 20px;
				margin-bottom: -10px;
				color: $color-grey;
				@include font(10px, false);
				line-height: 1;
				text-transform: uppercase;
			}

			.b2b-navigation-bottom_link {
				padding: 30px 20px;
				@include form-select();
			}
		}

		.b2b-navigation-item {
			border-color: $color-black;
		}

		.b2b-navigation-top_link {
			color: $color-grey_light2;
		}
	}
}

.b2b-menu-toggle {
	&.active {
		background: none;
		color: $color-white;
	}
}
