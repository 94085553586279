@mixin header-utility-flyout {
	color: $color-grey_light2;
	
	a {
		color: $color-grey2;
	}

	.f-field > .f-label {
		color: $color-white;
	}
	
	.f-textinput,
	.f-select,
	.f-textarea {
		background-color: $color-none;
		color: $color-grey_light;
	}

	.f-select {
		background-image: url(select_white.png), linear-gradient(to bottom, $color-form-border 0%, $color-form-border 100%);

		&.error {
			background-image: url(select_white.png), linear-gradient(to bottom, $color-red 0%, $color-red 100%);
		}

		&:hover,
		&:focus,
		&.error:hover {
			background-image: url(select_white.png), linear-gradient(to bottom, $color-grey 0%, $color-grey 100%);
		}
	}

	.f-field-checkbox .f-label {
		&:hover:before {
			background-color: $color-none;
			border-color: $color-grey;
		}
	}
	
	@include placeholder {
		color: $color-grey2;
	}
	
	.b-button {
		color: $color-black;
		background-color: $color-grey_light3;
		
		&:hover {
			color: $color-grey_light3;
			background-color: $color-none;
			border-color: $color-grey_light3;
		}
	
		&.m-text {
			background: none;
			color: $color-grey_light;
			
			.b-button-inner {
				color: $color-white;
			}
		}
	}
}

.p-login_popup {
	@include header-utility-flyout;
}

.p-newsletter_popup,
.p-contactus_popup {
	@include respond-to(desktop) {
		@include header-utility-flyout;
		padding: 0 50px;
		
		.b-services-icon {
			color: $color-grey_light2;
		}
	}
}

.p-newsletter_popup .f-field.f-field-fullname {
	width: 33%;
}

.js-utility_flyout-link.active {
	@include respond-to(desktop) {
		pointer-events: none;
	}
}
