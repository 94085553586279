@mixin base(
	$unlimited-width: true,
	$wide-width: true,
	$content-margin: 80px
) {
	html {
		min-height: 100%;
		font-size: $font_size;
		letter-spacing: $letter_spacing;
		font-family: $font_family;
		-webkit-text-size-adjust: 100%; //Prevent font scaling in landscape while allowing user zoom */
		-webkit-text-decoration-skip: none; //Fixes gaps in text-decorarion: underline on safari and IOS devices

		&.safari {
			-webkit-font-smoothing: antialiased;
		}
	}
	
	body {
		color: $color-text;
	}
	
	a {
		color: $color-text;
	}
	
	%b-primary,
	.b-primary {
		overflow: hidden;
		padding: 0 $gutter-global;
	}
	
	%b-primary {
		@include respond-to(desktop) {
			float: right;
			width: $primary-width;
		}
	}
	
	%b-secondary,
	.b-secondary {
		padding: 0 $gutter-global;
		
		@include respond-to(desktop) {
			float: left;
			width: $secondary-width;
		}
	}
	
	.b-content {
		max-width: $content-width;
		margin: 0 auto;
		
		@if $wide-width {
			.pt_product-search-result & {
				max-width: $content-width_wide;
			}
		}
		
		@if $unlimited-width {
			.pt_storefront &,
			.pt_categorylanding & {
				max-width: none;
			}
		}
		
		
		@include respond-to(desktop) {
			.b-title_box + & {
				margin-top: $content-margin;
				
				.pt_product-details & {
					margin-top: 0;
				}
			}
			
			.b-account-title + & {
				margin-top: $content-margin / 2;
			}
		}
	}
	
	.clearfix {
		@include clearfix;
	}
	
	.iframe-wrapper {
		overflow: hidden;
	}
}
