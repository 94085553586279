/*
* Size guides links on PDP
*/
$gray-accent: #f5f5f5;

.b-size {
  &_links-container {
    text-align: right;
    flex: 1;
  }
  &_chart-link {
    display: block;
    color: $color-grey2;
    @include font($font_size-small, false);
  }
  &_guide-link {
    margin-top: 5px;
  }
}

/*
* Common styles for both size popups
*/
.dialog_size-comparison,
.dialog_size-guide {
  top: 5% !important;//prevent jump at tabs switching
  max-width: 850px !important;
  &.ui-dialog {
    background-color: $color-white;
    padding: 20px 30px;
  }
  .ui-dialog-content {
    padding: 0 !important;
  }
  .ui-dialog-title {
    font-size: 18px;
    text-align: center;
    text-transform: none;
  }
  .ui-dialog-titlebar-close .ui-icon-closethick:before {
    font-size: 22px;
  }

  @include respond-to(phone) {
    max-height: 95vh;
    .ui-dialog-title {
      font-size: 17px;
    }
  }
}

.dialog_size-guide {
  .item-scheme {
    margin-top: 15px;
  }

  ol {
    padding-left: 15px;
  }

  ol li {
    list-style: auto;
    margin-bottom: 5px;
  }

  .table-title-wrapper {
    display: flex;
    align-items: baseline;
    gap: 15px;

    @include respond-to(phone) {
      margin-bottom: 15px;
    }
  }

  .measure-container {
    display: flex;
    align-items: flex-end;
    gap: 8px;

    .measure {
      transition: 0.3s;

      &.active {
        font-weight: 700;
      }
    }

    .checkbox-container {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 40px;
      height: 20px;
      border: 1px solid $color-black;
      border-radius: 50px;
      background-color: $color-grey_light;

      input[type="checkbox"] {
        display: none;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 1px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: $color-black;
      transition: all 0.2s ease-in-out;
    }

    input:checked + .checkmark {
      transform: translateX(18px);
    }
  }
}

/*
* Size comparison
*/
.dialog_size-comparison { //popup
  @include respond-to(not-phone) {
    padding: 0 20px;
  }
  tr {
    border-bottom: none !important;
    &:nth-child(2n) {
      background-color: $gray-accent;
    }
  }
  td {
    min-width: 40px;
    text-align: center;

    &:first-child {
      width: 10%;
      text-align: left;
      padding-left: 8px;
    }
  }
  .toggle-tabs {
    .toggle {
      background-color: $gray-accent;
      &.expanded {
        background-color: $color-grey;
      }
    }
  }
}

/*
* Size Guide (product-dependent)
*/
.size-guide {
  &_content {
     h5 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
      margin-top: 15px;
      text-transform: uppercase;
    }
  }
  &_info {
    margin-top: 0;
    font-size: 12px;
    color: $color-grey;
    width: 100%;
    max-width: none;
    display: flex;

    @include respond-to(phone) {
      flex-wrap: wrap;
    }

    p {
      margin-bottom: 8px;
      font-size: $font_size-small;
      line-height: 1.2;
    }
  }

  &_note {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: auto;
    transform: none;
    left: 0;
    padding: 0 15px;
    max-width: 95%;
    height: auto;
    align-items: start;
    color: $color-black;

    @include respond-to(phone) {
      padding: 0;
      order: 1;
    }
    p {
      color: $color-black;
      text-align: left;
      margin: 0;
    }
  }
  &_footer {
    p {
      color: $color-grey;
      text-align: left;
      margin: 0 0 8px;
    }
  }
  &_table-container {
    margin-bottom: 30px;
    width: 96%;
    max-width: none;

    @include respond-to(phone) {
      width: 100%;
    }

    @include respond-to(desktop) {
      overflow-x: auto;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 7px;
      }
      &::-webkit-scrollbar-track {
        background: $color-white;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-grey;
        border-radius: 5px;
      }
    }
  }
  &_overflow-shadow { // added by js
    margin-left: 15px;
    box-shadow: 40px 0px 30px -37px rgba(34, 60, 80, 0.2) inset,
    -40px 0px 30px -37px rgba(34, 60, 80, 0.2) inset;
  }

  // Flex table - mobile
  &_table {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;
  }
  &_row {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    & span { // cell
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex: 1;
      padding: 8px 5px;
      min-width: 40px;
      color: $color-black;
      font-weight: 400;
      &:nth-child(2n) {
        background-color: $gray-accent;
      }
    }
  }
  &_row-outer {
    background-color: $color-white;
    flex-flow: row nowrap;
    border-left: none;

    &.first {
      max-width: 100px;
    }
  }
  &_th {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    min-height: 30px;
  }
  &_th {
    min-width: auto;
    display: flex;
    align-items: flex-start;
    min-height: 40px;
    font-size: 12px;
    white-space: wrap;
    text-transform: uppercase;
    border-bottom: 2px solid $color-black;
    color: $color-black;
  }
  &_row_size {
    font-weight: bold;
    text-transform: uppercase;
  }
  &_title {
    font-size: 26px;
    font-weight: 700;
    color: $color-black;
    text-transform: capitalize;
  }

  // end flex table - mobile

  // Flex table - desk
  @include respond-to(desktop) {
    &_table {
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
    }
    &_row {
      flex-direction: column;
      flex: 1;
      width: unset;
      &:nth-child(2n) {
        background-color: transparent;
      }
    }
    &_th {
      min-height: unset;
      justify-content: center;
      align-items: center;
      padding: 12px 5px 12px 15px !important;
      font-size: 12px;
      text-align: left !important;
    }
  }
  // end flex table - desk
}
