// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$flags:  (
	'ad': (-40px, 0px),
	'ae': (-360px, -28px),
	'af': (0px, -28px),
	'ag': (-40px, -28px),
	'al': (-80px, 0px),
	'am': (-80px, -28px),
	'an': (0px, -56px),
	'ar': (-40px, -56px),
	'at': (-80px, -56px),
	'au': (0px, -84px),
	'aw': (-40px, -84px),
	'az': (-80px, -84px),
	'ba': (-120px, 0px),
	'bb': (-120px, -28px),
	'bd': (-120px, -56px),
	'be': (-120px, -84px),
	'bf': (0px, -112px),
	'bg': (-40px, -112px),
	'bh': (-80px, -112px),
	'bi': (-120px, -112px),
	'bj': (-160px, 0px),
	'bl': (-160px, -28px),
	'bm': (-160px, -56px),
	'bn': (-160px, -84px),
	'bo': (-160px, -112px),
	'br': (0px, -140px),
	'bs': (-40px, -140px),
	'bt': (-80px, -140px),
	'bw': (-120px, -140px),
	'by': (-160px, -140px),
	'bz': (0px, -168px),
	'ca': (-40px, -168px),
	'cd': (-80px, -168px),
	'cf': (-120px, -168px),
	'cg': (-160px, -168px),
	'ch': (-200px, 0px),
	'ci': (-200px, -28px),
	'ck': (-200px, -56px),
	'cl': (-200px, -84px),
	'cm': (-200px, -112px),
	'cn': (-200px, -140px),
	'co': (-200px, -168px),
	'cr': (0px, -196px),
	'cu': (-40px, -196px),
	'cv': (-80px, -196px),
	'cy': (-120px, -196px),
	'cz': (-160px, -196px),
	'de': (-200px, -196px),
	'dj': (-240px, 0px),
	'dk': (-240px, -28px),
	'dm': (-240px, -56px),
	'do': (-240px, -84px),
	'dz': (-240px, -112px),
	'ec': (-240px, -140px),
	'ee': (-240px, -168px),
	'eg': (-240px, -196px),
	'er': (0px, -224px),
	'es': (-40px, -224px),
	'et': (-80px, -224px),
	'fi': (-120px, -224px),
	'fj': (-160px, -224px),
	'fk': (-200px, -224px),
	'fm': (-240px, -224px),
	'fo': (0px, -252px),
	'fr': (-40px, -252px),
	'ga': (-80px, -252px),
	'gb': (-120px, -252px),
	'gd': (-160px, -252px),
	'ge': (-200px, -252px),
	'gf': (-240px, -252px),
	'gh': (-280px, 0px),
	'gi': (-280px, -28px),
	'gl': (-280px, -56px),
	'gm': (-280px, -84px),
	'gn': (-280px, -112px),
	'gp': (-280px, -140px),
	'gq': (-280px, -168px),
	'gr': (-280px, -196px),
	'gt': (-280px, -224px),
	'gu': (-280px, -252px),
	'gw': (0px, -280px),
	'gy': (-40px, -280px),
	'hk': (-80px, -280px),
	'hn': (-120px, -280px),
	'hr': (-160px, -280px),
	'ht': (-200px, -280px),
	'hu': (-240px, -280px),
	'id': (-280px, -280px),
	'ie': (-320px, 0px),
	'il': (-320px, -28px),
	'in': (-320px, -56px),
	'iq': (-320px, -84px),
	'ir': (-320px, -112px),
	'is': (-320px, -140px),
	'it': (-320px, -168px),
	'jm': (-320px, -196px),
	'jo': (-320px, -224px),
	'jp': (-320px, -252px),
	'ke': (-320px, -280px),
	'kg': (0px, -308px),
	'kh': (-40px, -308px),
	'ki': (-80px, -308px),
	'km': (-120px, -308px),
	'kn': (-160px, -308px),
	'kp': (-200px, -308px),
	'kr': (-240px, -308px),
	'kw': (-280px, -308px),
	'ky': (-320px, -308px),
	'kz': (-360px, 0px),
	'la': (0px, 0px),
	'lb': (-360px, -56px),
	'lc': (-360px, -84px),
	'li': (-360px, -112px),
	'lk': (-360px, -140px),
	'lr': (-360px, -168px),
	'ls': (-360px, -196px),
	'lt': (-360px, -224px),
	'lu': (-360px, -252px),
	'lv': (-360px, -280px),
	'ly': (-360px, -308px),
	'ma': (0px, -336px),
	'mc': (-40px, -336px),
	'md': (-80px, -336px),
	'me': (-120px, -336px),
	'mg': (-160px, -336px),
	'mh': (-200px, -336px),
	'mk': (-240px, -336px),
	'ml': (-280px, -336px),
	'mm': (-320px, -336px),
	'mn': (-360px, -336px),
	'mo': (0px, -364px),
	'mq': (-40px, -364px),
	'mr': (-80px, -364px),
	'ms': (-120px, -364px),
	'mt': (-160px, -364px),
	'mu': (-200px, -364px),
	'mv': (-240px, -364px),
	'mw': (-280px, -364px),
	'mx': (-320px, -364px),
	'my': (-360px, -364px),
	'mz': (-400px, 0px),
	'na': (-400px, -28px),
	'nc': (-400px, -56px),
	'ne': (-400px, -84px),
	'ng': (-400px, -112px),
	'ni': (-400px, -140px),
	'nl': (-400px, -168px),
	'no': (-400px, -196px),
	'np': (-400px, -224px),
	'nr': (-400px, -252px),
	'nz': (-400px, -280px),
	'om': (-400px, -308px),
	'pa': (-400px, -336px),
	'pe': (-400px, -364px),
	'pf': (0px, -392px),
	'pg': (-40px, -392px),
	'ph': (-80px, -392px),
	'pk': (-120px, -392px),
	'pl': (-160px, -392px),
	'pr': (-200px, -392px),
	'pt': (-240px, -392px),
	'pw': (-280px, -392px),
	'py': (-320px, -392px),
	'qa': (-360px, -392px),
	'ro': (-400px, -392px),
	'rs': (-440px, 0px),
	'ru': (-440px, -28px),
	'rw': (-440px, -56px),
	'sa': (-440px, -84px),
	'sb': (-440px, -112px),
	'sc': (-440px, -140px),
	'sd': (-440px, -168px),
	'se': (-440px, -196px),
	'sg': (-440px, -224px),
	'si': (-440px, -252px),
	'sk': (-440px, -280px),
	'sl': (-440px, -308px),
	'sm': (-440px, -336px),
	'sn': (-440px, -364px),
	'so': (-440px, -392px),
	'sr': (0px, -420px),
	'ss': (-40px, -420px),
	'st': (-80px, -420px),
	'sv': (-120px, -420px),
	'sy': (-160px, -420px),
	'sz': (-200px, -420px),
	'tc': (-240px, -420px),
	'td': (-280px, -420px),
	'tg': (-320px, -420px),
	'th': (-360px, -420px),
	'tj': (-400px, -420px),
	'tl': (-440px, -420px),
	'tm': (0px, -448px),
	'tn': (-40px, -448px),
	'to': (-80px, -448px),
	'tr': (-120px, -448px),
	'tt': (-160px, -448px),
	'tv': (-200px, -448px),
	'tw': (-240px, -448px),
	'tz': (-280px, -448px),
	'ua': (-320px, -448px),
	'ug': (-360px, -448px),
	'us': (-400px, -448px),
	'uy': (-440px, -448px),
	'uz': (-480px, 0px),
	'va': (-480px, -28px),
	'vc': (-480px, -56px),
	've': (-480px, -84px),
	'vg': (-480px, -112px),
	'vi': (-480px, -140px),
	'vn': (-480px, -168px),
	'vu': (-480px, -196px),
	'ws': (-480px, -224px),
	'xk': (-480px, -252px),
	'ye': (-480px, -280px),
	'za': (-480px, -308px),
	'zm': (-480px, -336px),
	'zw': (-480px, -364px),
	'default': (100%, 100%)
);

$flags_width: 520px;

.flag-icon:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 14px;
	background: url(../images/sprite_flags.png) -100% -100% no-repeat;
	background-size: $flags_width/2 auto;
}

@each $key, $pos in $flags {
	.flag-icon-#{$key}:before {
		background-position: nth($pos, 1)/2 nth($pos, 2)/2;
	}
}


