.b2b-utility_menu {
	@include font(0, 0);
	position: relative;
	text-align: right;

	@include respond-to(desktop) {
		background-color: $color-utility_menu;
	}

	@include respond-to(not-desktop) {
		@include order(2);
	}

	&-item {
		display: inline-block;
		vertical-align: top;
		@include font;
		text-align: left;

		@include respond-to(desktop) {
			position: relative;
		}

		@include respond-to(tablet) {
			min-width: 50px;
		}

		@include respond-to(phone) {
			min-width: 25%;

			&:before {
				content: '';
				float: left;
				width: 1px; height: 20px;
				background: $color-black3;
				margin: 2px -1px;
			}
		}
	}

	&-link {
		@extend %utility_menu-link;

		&:hover {
			color: $color-white;
		}

		.b2b-utility_menu-item:first-child > & {
			border-left: 0;
		}

		&.active {
			@extend %utility_menu-link-active;
		}
	}

	&-fake_link {
		position: absolute;
		top: 0; left: 0;
		right: 0; bottom: 0;
	}

	&-popup {
		@extend %utility_menu-popup;
		display: none;
	}

	&-popup &-link {
		color: $color-text;
	}
}

.b2b-utility_popup {
	position: absolute;
	width: 100%;
	z-index: $z-min;
	margin-top: 1px;

	@include respond-to(not-desktop) {
		@include order(3);
		top: 100%; left: 0;
	}

	&-item {
		display: none;
		width: 100%;
		background-color: $color-utility_popup;

		@include respond-to(desktop) {
			position: relative;
		}

		@include respond-to(not-desktop) {
			position: absolute;
			top: 0; right: 0;
		}

		@include respond-to(tablet) {
			&.b2b-header-login {
				float: right;
				width: 320px;
			}
		}

		@include respond-to(phone) {
			width: 100%;
		}

		&.active {
			.desktop & {
				display: block;
			}

			@include respond-to(not-desktop) {
				position: static;
			}
		}

		&.js-iframe_loading {
			display: block;
			height: 0;
			overflow: hidden;
		}
	}

	&-content {
		padding: 30px 0;

		@include respond-to(not-desktop) {
			padding: 30px;
		}

		&.b-login {
			max-width: $content-width;
			margin: 0 auto;
		}
	}

	&-close {
		position: absolute;
		top: 30px; right: 25px;
		color: $color-white;
		cursor: pointer;

		&:before {
			@include font(20px, 0);
		}
	}
}

.icon-user {
	@include icon(account);
}

%utility_menu-link {
	@include font(10px);

	display: block;
	padding: 0 10px;
	line-height: $utility_menu-height;
	color: $color-grey2;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;

	@include respond-to(tablet) {
		line-height: $utility_menu-height_tablet;
	}

	@include respond-to(phone) {
		padding: 2px 0 0;
		height: $utility_menu-height_mobile;
		line-height: 1;
	}

	&:before {
		line-height: 1;

		@include respond-to(desktop) {
			@include font(18px, false);
			display: inline-block;
			vertical-align: middle;
		}

		@include respond-to(not-desktop) {
			@include font(22px, false);
		}
	}

	> span {
		display: inline-block;
		line-height: 1;

		@include respond-to(not-desktop) {
			display: none;
		}
	}
}

%utility_menu-link-active {
	color: $color-white;

	&:before {
		@include respond-to(not-desktop) {
			content: icon-char(close);
		}
	}
}

%utility_menu-popup {
	position: absolute;
	z-index: $z-utility-popup;
	top: 100%;
	right: 0;
	min-width: 160px;
	background-color: $color-utility_popup;
	box-shadow: 3px 3px 6px 0 fade-out($color-black, 0.9);

	@include respond-to(not-desktop) {
		width: 100%;
	}
}

.desktop .js-header-flyout_opened {
	overflow: hidden;

	.ui-widget-overlay {
		display: none;
	}

	// Add scrollbar to page wrapper to prevent page scrolling and header minimization
	#wrapper {
		overflow-x: hidden;
		overflow-y: scroll;
	}
}

.js-header-flyout_opened {
	@include respond-to(desktop) {
		&:before {
			content: '';
			position: fixed;
			z-index: $z-utility-flyout;
			top: 0; left: 0;
			right: 0; bottom: 0;
			background-color: $color-black;
			opacity: 0.95;
		}

		.b2b-utility_popup-item {
			height: 100vh;
			background: none;
		}

		.b2b-header-promotion {
			display: none;
		}
	}
}
