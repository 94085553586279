// Visibility of elements
.h-visible {
	display: block;
	
	@include respond-to(tablet) {
		display: none;
	}
	@include respond-to(phone) {
		display: none;
	}

	&_desktop {
		@include respond-to(not-desktop) {
			display: none;
		}
	}
	
	&_not_desktop {
		@include respond-to(desktop) {
			display: none;
		}
	}

	&_tablet {
		display: none;

		@include respond-to(tablet) {
			display: block;
		}
		@include respond-to(phone) {
			display: none;
		}
	}

	&_phone {
		display: none;

		@include respond-to(tablet) {
			display: none;
		}
		@include respond-to(phone) {
			display: block;
		}
	}

	&_not_phone {
		@include respond-to(not-phone) {
			display: block;
		}
		@include respond-to(phone) {
			display: none;
		}
	}
}

%visually-hidden,
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
