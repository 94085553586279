// Footer
// 'b-' prefixes only

.b-footer {
	position: relative;
	z-index: $z-footer;
	margin-top: 40px;
	padding: 0 90px;
	background: $color-footer;
	@include font(13px, $letter_spacing);
	color: $color-grey2;
	
	@include respond-to(not-desktop) {
		text-align: center;
	}
	
	@include respond-to(tablet) {
		padding: 0 30px;
	}
	
	@include respond-to(phone) {
		margin-top: 20px;
		padding: 0;
	}
	
	.pt_storefront &,
	.pt_categorylanding &,
	.pt_content-search-result &,
	.pt_product-search-result & {
		margin-top: 0;
	}
	
	a {
		@include transition(color);
		color: $color-grey;
		
		&:hover {
			color: $color-grey_light;
		}
	}
	
	&-copyright {
		padding: 25px 0;
		line-height: 24px;
		text-align: center;
		@include font(11px, false);
	}
	
	&-social {
		text-align: center;
		padding: 50px 0;
		border-bottom: 1px solid $color-black3;
		
		@include respond-to(phone) {
			text-align: left;
			padding: 25px 0;
			@include font(0, 0);
		}
	}
}

.b-newsletter {
	min-height: 170px;
	padding: 50px 0;
	text-align: center;
	border-bottom: 1px solid $color-black3;
	
	@include respond-to(phone) {
		margin: 0 15px;
	}
	
	&-title {
		margin-bottom: 15px;
		@include font(22px, 2px);
		line-height: 25px;
		color: $color-white;
	}
	
	&-description {
		margin-bottom: 20px;
		@include font(13px, false);
	}
	
	&-form {
		@include clearfix;
		max-width: 360px;
		margin: 0 auto;
		overflow: hidden;
		
		.f-textinput {
			background: none;
			color: $color-grey;
			border-color: $color-grey;
			
			&.error {
				border-color: $color-red;
			}
		}
		
		.b-button {
			min-width: 100px;
			height: $input-height;
			padding: 0 10px;
			color: $color-black;
			text-align: center;
			font-weight: 700;
		}

		@include respond-to(not-phone) {
			@include flexbox;
			@include wrap;
			text-align: left;

			.f-textinput,
			.b-button {
				@include text-ellipsis;
				width: auto;
			}
		
			.f-textinput {
				@include flex(1);
				margin-right: -1px;
				border-right: 0;
				
				&.error {
					& ~ button {
						border-color: $color-red;
					}
				}
			}
			
			.b-button {
				margin: 0 0 0 -1px;
				border-left: 0;
				@include box-shadow(inset 0px 0px 0px 2px $color-black);
			}

			span.error {
				@include order(1);
				width: 100%;
			}
		}
		
		@include respond-to(phone) {
			.b-button {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
}

.b-social {
	&-link {
		margin: 0 20px;
		text-decoration: none;
		
		&:before {
			@include font(30px, false);
		}
		
		@include respond-to(phone) {
			display: inline-block;
			width: percentage(1 / 3);
			margin: 0;
			padding: 25px;
			vertical-align: middle;
			text-align: center;
		}
	}
}

@include respond-to(desktop) {
	.b-footer_menu {
		@include flexbox;
		@include jc-space-around;
		padding: 50px 0 60px;
		border-bottom: 1px solid $color-black3;
		@include font(11px, false);
		line-height: 24px;
		text-transform: uppercase;
		
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		
		&-title {
			@include font(16px, false);
			color: $color-white;
			
			&.menu-item-toggle {
				display: block;
			}
		}
		
		&-submenu {
			min-width: 180px;
			
			> li {
				margin-top: 5px;
			}
		}
	}
}

@include respond-to(not-desktop) {
	.b-footer_menu {
		@include mobile-nav_menu;
		margin-top: -1px;
		text-align: left;
		
		&-submenu {
			@include mobile-nav_submenu($color-black2);
			
		
			li:first-child {
				border: none;
			}
			
			li + li {
				border-color: $color-black;
			}
			
			a {
				padding: ($navigation_item-height_mobile - 20px) / 2 30px ($navigation_item-height_mobile - 20px) / 2 45px;
				color: $color-grey2;
				text-transform: uppercase;
			}
		}
		
		&-title {
			@include mobile-nav_menu-link;
			@include font(14px, 2px);
			text-transform: uppercase;
			
			&.menu-item-toggle {
				position: static;
				text-align: left;
				color: $color-grey_light2;
				
				&:before {
					float: right;
				}
			}
		}
	}
}

@include respond-to(tablet) {
	.b-footer_menu {
		.b-footer_menu-submenu {
			padding: 0 30px;
			
			a {
				padding: 25px 0;
			}
		}
		
		&-title.menu-item-toggle {
			padding: 25px 0;
		}
	}
}

@include respond-to(phone) {
	.b-footer_menu {
		&-title.menu-item-toggle {
			padding: 25px 30px;
		}
	}
}
