@mixin dialog($bg-dialog: $color-white, $bg-overlay: $color-black) {
	.ui-widget-overlay {
		position: fixed;
		z-index: $z-dialog - 1;
		top: 0; left: 0; right: 0;
		width: 300%; height: 9999px;
		background-color: $bg-overlay;
		opacity: 0.75;
	}

	.ui-dialog {
		position: fixed;
		z-index: $z-dialog;
		left: 0 !important;
		right: 0 !important;
		width: 100% !important;
		max-height: 98%;
		margin: 0 auto;
		overflow: auto;
		outline: 0;
		-webkit-overflow-scrolling: touch;
		background-color: $bg-dialog;

		.desktop & {
			@include transition(top);
		}

		.no-desktop & {
			top: 50% !important;
			@include transform(translate(0, -50%));
			max-height: 100%;
		}

		.h-hidden_dialog {
			display: none;
		}

	&-close_overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: url(../images/close.cur), pointer; //for Edge
			cursor: url(../images/close.cur) 12 12, pointer; //for normal browsers */
			@include respond-to(not-desktop) {
				width: auto;
				height: auto;
			}
		}
	}

	.ui-draggable-handle {
		cursor: move;
	}

	.ui-dialog-title {
		display: none;

		.dialog-with-title & {
			display: block;
		}
	}

	.ui-dialog-titlebar-close {
		position: absolute;
		z-index: $z-min + 1;
		top: 20px; right: 20px;
		padding: 0;
		border: 0;
		background: none;
		color: $color-text;

		&:hover,
		&:active {
			opacity: 0.75;
			background: none;
			box-shadow: none;
		}

		.ui-button-text {
			display: none;
		}
	}

	.ui-dialog-buttonpane {
		text-align: center;

		button {
			margin: 10px 5px 0;
		}
	}

	.desktop .js-dialog_opened {
		overflow: hidden;
	}

	.js-scroll-disabled {
		position: fixed;
		top: 0; left: 0;
		width: 100%;
		height: 100%;

		#wrapper {
			height: 100%;
			overflow: auto;
		}
	}
}
