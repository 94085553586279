.b-pdp {
	&-image {
		width: 100%;
	}
	
	&-image_wrapper {
		position: relative;
		overflow: hidden;

		iframe {
			z-index: -1;
		}
		
		// to prevent content jumping while loading image of product
		&:before {
			content: '';
			float: left;
			padding-bottom: percentage(768/603); // image on PDP has resolution 603x768
		}
		
		@include respond-to(desktop) {
			overflow: hidden;
		}

		iframe {
			width: 100%;
		}
	}
	
	&-product_images {
		position: relative;
		text-align: center;
		
		@include respond-to(not-phone) {
			.owl-nav {
				display: none;
			}
		}
		
		@include respond-to(desktop) {
			float: left;
			width: 65%;
		}
		
		@include respond-to(tablet) {
			float: left;
			width: 55%;
		}
		
		@include respond-to(phone) {
			max-width: 100%;
			width: 360px;
			margin: 0 auto;
		}
	}
	
	&_carousel {

		@include respond-to(desktop) {
			float: right;
			width: 92%;
			
			.owl-stage {
				transform: none !important;
				width: auto !important;
			}
			
			.owl-item {
				display: inline !important;
				float: none !important;
				width: auto !important;
			}
			
			.owl-carousel-item {
				float: left;
				width: 50%;
			}
			
			.b-pdp-fullwidth_image,
			.owl-carousel-item:nth-child(5n),
			.owl-item:nth-child(5n) .owl-carousel-item:not(.m-pdp-video-item) {
				width: 100%;
			}
			
			.b-pdp-fullwidth_image,
			.owl-carousel-item:nth-child(5n+3),
			.owl-item:nth-child(5n+3) .owl-carousel-item {
				clear: left;
			}
			
			.owl-controls,
			.cloned {
				display: none;
			}
			
			.b-pdp-image_wrapper {
				padding: $gutter-pdp 0 0 $gutter-pdp;
			}
		}
		
		@include respond-to(tablet) {
			padding-right: $gutter-pdp;
			
			.owl-stage {
				transform: none !important;
				width: auto !important;
			}
			
			> .owl-carousel-item,
			.owl-item {
				float: left;
				width: 100% !important;
			}
			
			.owl-controls,
			.cloned {
				display: none;
			}
			
			.b-pdp-image_wrapper {
				padding-top: $gutter-pdp;
			}
		}
		
		@include respond-to(phone) {
			&.owl-loaded {
				overflow: visible;
			}
			
			.owl-dots {
				@include flexbox;
				@include jc-center;
				@include font(0, 0);
				margin-top: 10px;
			}
			
			.owl-dot {
				width: 15%;
				
				& + .owl-dot {
					margin-left: 2%;
				}
			}
			
			.owl-next,
			.owl-prev {
				top: 50%;
				bottom: auto;
				width: 40px;
				line-height: 40px;
				margin: -30px -#{$gutter-global};
				background-color: $color-black3;
				
				&:before {
					position: static;
					@include transform(none);
					@include font(20px);
				}
			}
		}
	}
	
	&-thumbnails {
		float: left;
		width: 8%;
		height: auto !important;
		padding-top: $gutter-pdp;
		
		@include respond-to(not-desktop) {
			display: none;
		}
	}
	
	&-thumbnail_list {
		margin: -2px 0;
	}
	
	&-thumbnail {
		cursor: pointer;
		margin: 2px 0;
		background-color: $color-white;
		
		&.m-360view {
			position: relative;
			
			&:after {
				content: '';
				position: absolute;
				top: 0; bottom: 0;
				left: 0; right: 0;
				@extend %bg-360view;
			}
			
			.b-pdp-thumbnail_image {
				opacity: 0.2;
			}
		}
	}
	
	&-product_label {
		position: absolute;
		top: 0; left: 0;
		margin: $gutter-pdp;
		padding: 7px;
		@include font(10px);
		line-height: 1;
		color: $color-grey;
		text-transform: uppercase;
		
		@include respond-to(desktop) {
			left: 8%;
		}
		
		@include respond-to(tablet) {
			margin-left: 0;
		}
		
		@include respond-to(phone) {
			margin: 0;
		}
	}
}
