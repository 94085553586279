.pt_customer-service {
	.b-services-wrapper {
		padding-bottom: 40px;
	}
}

.b-services {
	line-height: 20px;
	text-align: center;

	&-wrapper {
		@include flexbox();
		@include jc-center();

		@include respond-to(phone) {
			@include wrap();
		}
	}

	&-info {
		@include respond-to(desktop) {
			max-width: 420px;
			padding: 0 40px;
		}

		@include respond-to(tablet) {
			max-width: 320px;
			padding: 0 30px;
		}

		@include respond-to(phone) {
			@include order(1);
			width: 100%;

			.content-asset {
				margin-top: 20px;
				padding-top: 20px;
				border-top: 1px solid $color-grey_light;
			}
		}
	}

	&-info + &-form {
		@include respond-to(desktop) {
			max-width: 600px;
			padding: 0 100px;
		}

		@include respond-to(tablet) {
			max-width: 480px;
			padding: 0 60px;
		}
	}

	&-form {
		overflow: hidden;
		margin: 0 auto;

		@include respond-to(not-phone) {
			.b-services-wrapper & {
				margin: 0;
				border-left: 1px solid $color-grey_light;
			}
		}

		@include respond-to(desktop) {
			max-width: 850px;
		}

		@include respond-to(tablet) {
			max-width: 730px;
		}

		.f-field {
			text-align: left;
		}

		.f-field-birth_date {
			width: 100%;
		}

		.f-textinput,
		.f-textarea,
		.f-select {
			width: 100%;
		}

		.f-description {
			margin: 20px 0;
			@include font($font_size, false);
		}
	}

	&-icon {
		display: block;
		text-decoration: none;

		@include respond-to(not-phone) {
			margin-bottom: 35px;
		}

		&:before {
			display: block;
			margin: 20px 0 5px;
			@include font(30px, false);
			line-height: 1;
		}
	}

	&-title {
		@extend %heading-1;
	}

	&-form &-title {
		@include respond-to(phone) {
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	&-description {
		margin: 10px 0 20px;
	}

	p {
		margin-bottom: 10px;
	}
}

.dialog-customer_services,
.dialog-newsletter_subscribe {
	@include respond-to(desktop) {
		position: fixed !important;
		z-index: $z-utility-flyout;
		top: 0 !important;
		bottom: 0;
		max-width: none;
		margin-top: $header-height;
		padding: 30px 0;
		background: none;
		overflow-x: hidden;
		overflow-y: auto;

		.ui-dialog-content {
			padding: 0;
		}

		.ui-dialog-titlebar-close {
			top: 30px; right: 25px;

			.ui-icon:before {
				@include font(20px, 0);
				color: $color-white;
			}
		}

		iframe {
			min-width: $width-desktop_small + 1;
		}

		.js-iframe_loading:before {
			@include invert;

			.ie & {
				background-image: url(../images/base64/philipp_plein.png);
			}
		}
	}

	@include respond-to(tablet) {
		max-width: 900px;

		.ui-dialog-content {
			margin: 0 -20px;
		}
	}
}
