@mixin user-menu {
	.b2b-user {
		&-info {
			display: block;
			overflow: hidden;
			left: auto;
			padding: 0;
		}

		&-info_item {
			display: block;
			margin-top: -1px;
			padding: 10px 20px;
			border-top: 1px solid $color-grey2;
			text-align: center;
			text-decoration: none;
			white-space: nowrap;

			&:hover {
				background-color: lighten($color-grey_light, 10%);
			}
		}
	}
}
