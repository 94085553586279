.b-login {
	&.b-login {
		display: block;
		width: auto;
		max-width: none;
		margin: 0;
		padding: 0;
	}

	&-body {
		min-height: 100%;
		background: $color-black url(../images/plein_b2b_home.jpg) 50% 50% no-repeat;
		background-size: cover;
	}

	&-wrapper {
		position: absolute;
		top: 15%; left: 15%;
		width: 500px;
		max-width: 70%;
		padding: 40px;
		background: $color-black-transparent;
		color: $color-white-transparent;


		@include respond-to(phone) {
			top: 0;
			left: 0; right: 0;
			margin: 10px;
			width: auto;
			max-width: none;
			padding: 20px;
		}
	}

	&-logo {
		.b2b-primary_logo {
			position: static;
			width: auto;
			height: auto;
			margin: 0 0 40px;
			padding: 0 0 40px;
			border-bottom: 1px solid $color-white-transparent;

			@include respond-to(phone) {
				margin-bottom: 20px;
				padding-bottom: 20px;
			}

			&,
			a {
				min-width: 0;
			}
		}

		a:before {
            display: none;
			//position: static !important;
			//display: block;
			//width: 100px !important;
			//height: 100px !important;
			//margin: 0 auto 20px;
			//background-position: 50% 50% !important;
			//background-size: 100% 100% !important;
		}

		img {
			height: auto;
		}

		&.m-logo-pleinSport {
			.b2b-primary_logo {
				@include respond-to(not-phone) {
					padding-bottom: 40px;
				}
			}

			a {
				&:before {
					width: 200px;
				}
			}
		}

		&.m-logo-billionaire {
			.b2b-primary_logo {
				padding: 10% 15%;
			}

			a {
				position: relative;

				&:before {
					content: '';
					position: absolute !important;
					top: 0; bottom: 0;
					left: 0; right: 0;
					width: auto !important;
					height: auto !important;
					max-height: none;
					margin: -7.5%;
					background-size: auto 100% !important;
					opacity: 0.4;
				}
			}
		}
	}

	&-item {
		padding: 0;
		border: 0;
	}

	&-description {
		margin: 0;
	}

	.f-field {
		margin: 20px 0 0;
	}

	.f-textinput {
		background-color: $color-none;
		color: $color-grey_light;
	}

	@include placeholder {
		color: $color-grey2;
	}

	.b-button {
		color: $color-black;
		background-color: $color-grey_light3;

		&:hover {
			color: $color-grey_light3;
			background-color: $color-none;
			border-color: $color-grey_light3;
		}
	}
}

@include keyframes(login-show) {
	100% { opacity: 1; }
}
