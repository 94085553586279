@mixin flexbox() {
	display: -ms-flexbox;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}
@mixin flexbox-inline() {
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
}
@mixin flex($values) {
	-ms-flex:  $values;
	-webkit-box-flex: $values;
	-webkit-flex:  $values;
	flex:  $values;
}

@mixin order($value) {
	-ms-flex-order: $value;
	-webkit-box-ordinal-group: $value;
	-webkit-order: $value;
	order: $value;
}

@mixin flex-grow($values) {
	-ms-flex-grow:  $values;
	-webkit-box-flex-grow: $values;
	-webkit-flex-grow:  $values;
	flex-grow:  $values;
}

@mixin flex-row() {
	-ms-flex-direction : row;
	-webkit-flex-direction : row;
	flex-direction : row;
}
@mixin flex-column() {
	-ms-flex-direction : column;
	-webkit-flex-direction : column;
	flex-direction : column;
}
@mixin row-reverse() {
	-ms-flex-direction : row-reverse;
	-webkit-flex-direction : row-reverse;
	flex-direction : row-reverse;
}
@mixin column-reverse() {
	-ms-flex-direction : column-reverse;
	-webkit-flex-direction : column-reverse;
	flex-direction : column-reverse;
}

@mixin row-wrap() {
	-webkit-flex-flow : row wrap;
	-ms-flex-flow : row wrap;
	flex-flow : row wrap;
}
@mixin column-wrap() {
	-ms-flex-flow : column wrap;
	-webkit-flex-flow : column wrap;
	flex-flow : column wrap;
}

@mixin wrap() {
	-webkit-flex-wrap : wrap;
	-ms-flex-wrap : wrap;
	flex-wrap : wrap;
}
@mixin no-wrap() {
	-webkit-flex-wrap : no-wrap;
	-ms-flex-wrap : none;
	flex-wrap : no-wrap;
}
@mixin wrap-reverse() {
	-webkit-flex-wrap : wrap-reverse;
	-ms-flex-wrap : wrap-reverse;
	flex-wrap : wrap-reverse;
}

@mixin ai-start() {
	-webkit-align-items : flex-start;
	-ms-flex-align : start;
	align-items : flex-start;
}
@mixin ai-end() {
	-webkit-align-items : flex-end;
	-ms-flex-align : end;
	align-items : flex-end;
}
@mixin ai-center() {
	-webkit-align-items : center;
	-ms-flex-align : center;
	align-items : center;
}
@mixin ai-stretch() {
	-webkit-align-items : stretch;
	-ms-flex-align : stretch;
	align-items : stretch;
}

@mixin as-start() {
	-webkit-align-self: flex-start;
	-ms-align-self: start;
	align-self: flex-start;
}
@mixin as-end() {
	-webkit-align-self: flex-end;
	-ms-align-self: end;
	align-self: flex-end;
}
@mixin as-center() {
	-webkit-align-self: center;
	-ms-align-self: center;
	align-self: center;
}
@mixin as-stretch() {
	-webkit-align-self: stretch;
	-ms-align-self: stretch;
	align-self: stretch;
}

@mixin jc-start() {
	-webkit-justify-content : flex-start;
	-ms-flex-pack : start;
	justify-content : flex-start;
}
@mixin jc-end() {
	-webkit-justify-content : flex-end;
	-ms-flex-pack : end;
	justify-content : flex-end;
}
@mixin jc-center() {
	-webkit-justify-content : center;
	-ms-flex-pack : center;
	justify-content : center;
}

@mixin jc-space-between() {
	-ms-justify-content : space-between;
	-webkit-justify-content : space-between;
	justify-content : space-between;
}
@mixin jc-space-around() {
	-ms-justify-content : space-around;
	-webkit-justify-content : space-around;
	justify-content : space-around;
}
