%heading-1 {
	margin-bottom: 20px;
	@include font($font_size-h1, $letter_spacing, $font_family-additional);
	line-height: 1.1;
	font-weight: 300;
	text-transform: uppercase;
		
	@include respond-to(tablet) {
		@include font(30px, false);
	}
	
	@include respond-to(phone) {
		@include font(26px, false);
	}
}

%heading-2 {
	margin-bottom: 15px;
	@include font($font_size-h2, $letter_spacing, $font_family-additional);
	line-height: 1.1;
	font-weight: 300;
	text-transform: uppercase;
}

%heading-3 {
	margin-bottom: 10px;
	@include font($font_size-h3);
	line-height: 1.5;
	font-weight: 600;
	text-transform: uppercase;
}
