@mixin b-breadcrumbs {
	@include font($font_size-breadcrumbs, false);
	
	&,
	a {
		color: $color-breadcrumbs;
		text-decoration: none;
	}
	
	&-element {
		display: inline-block;
		
		&:hover {
			.breadcrumb-text {
				text-decoration: none;
			}
		}
		
		&:after {
			content: '\00A0\003E';
			display: inline-block;
		}
		
		&:last-child {
			.breadcrumb-text {
				text-decoration: none;
			}
			
			&:after {
				content: none;
			}
		}
	}
}
