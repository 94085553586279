@mixin f-form-horizontal() {
	.f-field {
		@include font(0,0);
	}

	.f-field > .f-label {
		display: inline-block;
		vertical-align: middle;
		width: 20%;
		margin: -10px 5% -10px 0;
		line-height: 1;
		text-align: right;
	}

	.f-caption,
	.error,
	.char-count {
		margin-left: 25%;
	}

	.f-textinput,
	.f-textarea,
	.f-select {
		display: inline-block;
		vertical-align: middle;
		width: 75%;
		margin-left: 0;
	}

	.f-field-phone_number {
		margin-left: 25%;

		.f-label {
			width: 100%;
			text-align: left;
		}

		.f-textinput,
		.f-select {
			width: 100%;
		}

		.error {
			margin-left: 0;
		}
	}

	.f-recaptcha {
		.error {
			text-align: center;
			margin-left: 0;
		}
	}
}

.f-field-inline {
	input,
	.f-label {
		display: inline-block;
	}
}

.f-radio-group {
	margin: 5px 0;
}

.f-label {
	display: block;
	@include font(14px);
}

.f-form-error {
	position: relative;
	margin-bottom: 20px;
	padding: 15px 15px 15px 50px;
	background-color: fade-out($color-error, 0.8);
	border: 2px solid $color-error;
	font-size: $font_size;
	line-height: 1.2;
	color: $color-error;

	&:before {
		content: '|';
		overflow: hidden;
		position: absolute;
		top: 50%; left: 15px;
		width: 20px; height: 20px;
		margin-top: -10px;
		border: 2px solid $color-error;
		border-radius: 100%;
		font-size: 200%;
		line-height: 50%;
		font-family: sans-serif;
		text-align: center;
		opacity: 0.75;
		@include transform(rotate(45deg));
	}
}

@mixin form-elements_base(
	$field_indent: 10px,
	$column_gutter: 20px,
	$caption-font_size: $font_size-small,
	$caption-letter_spacing: $letter-spacing
) {
	// Required indicators
	.required-indicator,
	.f-required-indicator {
		&:before {
			content: '* ';
			font-size: $font_size;
			line-height: 1;
			color: $color-required;
		}
	}

	.f-required {
		@include font($caption-font_size, $caption-letter_spacing);
		color: $color-form-details;
		text-align: right;
	}

	.dialog-required {
		display: block;
		width: 100%;
		text-align: right;
		@include font(0, 0);
		line-height: normal;
		color: $color-form-details;

		em {
			@include font($caption-font_size, $caption-letter_spacing);
		}
	}

	legend {
		width: 100%;
	}

	// Captions
	.char-count,
	.form-caption,
	.f-caption,
	.f-description {
		@include font($caption-font_size, $caption-letter_spacing);

		&,
		a {
			color: $color-form-caption;
		}
	}

	// Errors
	.error-message,
	.f-caption.error-message,
	.error-form,
	.error {
		@include font($caption-font_size, $caption-letter_spacing);
		display: block;
		color: $color-error;

		&.hidden {
			display: none;
		}
	}

	.error-form {
		margin-bottom: 10px;
	}

	.f-form-warning {
		@include font($caption-font_size, $caption-letter_spacing);
		font-weight: 600;
		color: $color-warning;
	}

	.form-row,
	.f-field {
		margin-bottom: $field_indent;
	}

	.h-service {
		display: none !important;
	}

	.f-field-hidden {
		position: absolute; // absolute position for hidden fields to avoid impact on positioning visible elements in the form
		margin: 0;

		.f-label,
		.f-caption {
			display: none;
		}
	}

	.f-field-tooltip {
		float: right;
		@include font($caption-font_size, $caption-letter_spacing);
	}

	.f-form-horizontal {
		@include respond-to(not-phone) {
			@include f-form-horizontal();
		}

		.f-radio-group {
			display: inline-block;
			margin: 9px 25px 10px 0;
		}
	}

	.f-form-columns {
		@include respond-to(not-phone) {
			@include font(0, 0);
			margin-right: -$column_gutter;

			.f-field {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				padding-right: $column_gutter;
			}

			.f-field-fullwidth,
			.f-field-checkbox {
				width: 100%;
			}

			.f-select,
			.f-textarea,
			.f-textinput {
				width: 100%;
			}
		}
		@include respond-to(phone) {
			f-field {
				width: 100%;
			}
		}

		.f-field.f-field {
			&-salutation {
				padding-right: 20px;
				width: 17%;

				@include respond-to(phone) {
					float: left;
					padding-right: 10px;
					width: 42%;
				}
			}

			&-firstname {
				width: 33%;

				@include respond-to(phone) {
					width: 58%;
					float: right;
				}
			}

			&-fullname {
				width: 83%;

				@include respond-to(phone) {
					width: 58%;
					float: right;
				}
			}
		}
	}

	.f-form-custom {
		@include respond-to(not-phone) {
			.f-form-columns {
				@include flexbox;
				@include row-wrap;
			}

			.f-field-lastname,
			.f-field-country {
				@include order(1);
			}

			.f-field-birth_date,
			.f-field-interests {
				@include order(2);
			}

			.f-field-checkbox {
				width: auto;
				margin: $input-height/4 0 0;
			}
		}

		@include respond-to(phone) {
			.f-field-checkbox {
				display: inline-block;
				margin: 0 15px 0 0;
			}
		}
	}

	.f-field-password {
		overflow: hidden;

		.f-caption {
			float: right;
		}

		span.error {
			display: inline-block;
			vertical-align: top;
		}

		&-wrapper {
			position: relative;
			.show-password {
				position: absolute;
				top: 0;
				right: 7px;
				width: 18px;
				height: $input-height;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
				opacity: 0.8;
			}

			&:has(input[type="password"]) {
				.show-password {// crossed eye black
					background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzLjE2MjQgMjguODY2OEMxNi41MTU4IDMwLjE2MjQgOC43NjYzMSAyOC40MzQgMS4yNjgxNSAxOS42MDE1QzQuMDk3MjMgMTUuODY4NCA5LjA5NDE0IDExLjY1MzYgMTUuNDEzMiAxMC4zODMyQzIxLjg0NTIgOS4wOTAxNCAyOS44NjU1IDEwLjgwNTQgMzguNjM5NyAxOS42NzdDMzUuMjU2OSAyMy40MTE4IDI5LjY1ODcgMjcuNjAwNSAyMy4xNjI0IDI4Ljg2NjhaIiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMiIvPgo8Y2lyY2xlIGN4PSIxOS41IiBjeT0iMTkuNSIgcj0iNS41IiBmaWxsPSIjMzMzMzMzIi8+CjxsaW5lIHgxPSIzMy43MDcxIiB5MT0iNS43MDcxMSIgeDI9IjYuNzA3MTEiIHkyPSIzMi43MDcxIiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
				}
			}
			&:has(input[type="text"]) {
				.show-password {// open eye black
					background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzLjE2MjQgMjguODY2OEMxNi41MTU4IDMwLjE2MjQgOC43NjYzMSAyOC40MzQgMS4yNjgxNSAxOS42MDE1QzQuMDk3MjMgMTUuODY4NCA5LjA5NDE0IDExLjY1MzYgMTUuNDEzMiAxMC4zODMyQzIxLjg0NTIgOS4wOTAxNCAyOS44NjU1IDEwLjgwNTQgMzguNjM5NyAxOS42NzdDMzUuMjU2OSAyMy40MTE4IDI5LjY1ODcgMjcuNjAwNSAyMy4xNjI0IDI4Ljg2NjhaIiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMiIvPgo8Y2lyY2xlIGN4PSIxOS41IiBjeT0iMTkuNSIgcj0iNS41IiBmaWxsPSIjMzMzMzMzIi8+Cjwvc3ZnPgo=');
				}
			}
		}
	}

	.f-field-phone_number {
		@include font(0, 0);

		.f-field-select,
		.f-field-text {
			display: inline-block;
			vertical-align: top;
		}

		.f-field-text,
		.f-field-select {
			width: 50%;
		}

		.f-textinput,
		.f-select {
			width: 100%;
			text-transform: none;
		}
	}

	.f-field-cvn {
		.f-textinput {
			vertical-align: middle;
			max-width: 50%;
		}

		.f-field-tooltip {
			float: none;
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
		}

		label.error,
		span.error {
			float: right;
			width: 100%;
		}
	}

	.f-field-expiration,
	.f-field-birth_date {
		overflow: hidden;

		.f-field.f-field {
			float: left;
			clear: none;
			margin-bottom: 0;
			padding-right: 0;

			+ .f-field {
				padding-left: 20px;

				@include respond-to(phone) {
					padding-left: 10px;
				}
			}
		}
	}

	.f-field-expiration {
		> .f-label {
			display: none;
		}

		.f-field.f-field {
			width: percentage(1/2);
		}
	}

	.f-field-birth_date {
		.f-field.f-field {
			> .f-label {
				display: none;
			}

			&.day {
				width: 25%;
			}

			&.month {
				width: 45%;
			}

			&.year {
				width: 30%;
			}
		}
	}

	.f-recaptcha {
		margin-bottom: 25px;
		.error {
			text-align: left;
			margin: 10px 0;
		}
	}
	.grecaptcha-badge {
		z-index: 99;
	}
}


