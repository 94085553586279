@mixin pdp-product_zoom(
	$icon_size: 20px
) {
	.b-zoom-link {
		position: absolute;
		z-index: $z-min;
		top: 0; right: 0;
		width: 100%; height: 100%;
		text-decoration: none;
		text-shadow: 0 0 15px $color-white;
		cursor: url(../images/zoom.cur), pointer; //for Edge
		cursor: url(../images/zoom.cur) 12 12, pointer; //for normal browsers

		&:before {
			position: relative;
			z-index: $z-min;
			float: right;
			padding: 10px;
			@include font($icon_size, false);
			cursor: pointer;

			.ie & {
				padding: 10px 10px 30px 30px;
				cursor: pointer;
			}
		}

		.zoomImg {
			display: none !important;
		}

		&.js-zoomed {
			&:before {
				content: icon-char(close);
			}

			.zoomImg {
				display: block !important;
			}
		}
	}

	.b-zoom-container {
		height: 100%;

		.zoomImg {
			@include transition(opacity);
		}

		&.js-loading {
			&:after {
				background-attachment: scroll;
			}

			.zoomImg {
				position: relative;
				opacity: 0 !important;
			}
		}
	}

	.dialog-image_zoom,
	.dialog-360view_zoom {
		max-width: none;
		max-height: none;
		background-color: $color-white;

		&.ui-dialog,
		.ui-dialog-content {
			height: 100% !important;
			overflow: hidden;
			padding: 0;
		}

		.ui-dialog-titlebar-close {
			top: 0;
			right: 0;
			padding: 20px;
			z-index: $z-loader + 1;
			color: $color-black;
			text-shadow: 0 0 15px $color-white;
			@include respond-to(not-desktop) {
				.ui-icon-closethick:before {
					font-size: 30px;
				}
			}
		}
	}
}
