.b-pdp {
  &-variations {

    @include respond-to(not-desktop) {
      margin: 20px 0 0;
    }
  }

  &-attribute {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border-top: 2px solid $color-pdp;
    padding: 25px 0;

    .label {
      margin-bottom: 15px;
      @include font($font_size, $letter_spacing-medium);
      text-transform: uppercase;

      @include respond-to(not-desktop) {
        margin-bottom: 15px;
      }

      &.toggle {
        cursor: default;

        &:after {
          display: none;
        }
      }

      .label-name {
        font-weight: 600;
      }

      > .selected-text,
      > .selected-value {
        display: none;
      }
    }

    .b-swatches-item {
      &.unselectable {
        .b-swatches-tooltip,
        .b-swatches-text {
          color: $color-grey_light;
        }

        .b-swatches-image {
          opacity: 0.5;
        }
      }
    }

    .b-swatches-link {
      display: block;
      text-decoration: none;
      text-transform: uppercase;
    }

    .b-swatches-tooltip,
    .b-swatches-text {
      padding: 5px 10px;
      @include font($font_size, false);
      color: $color-text_light;
    }

    .b-swatches-image {
      max-width: 26px;
      max-height: 14px;
      border: 1px solid $color-pdp;
      overflow: hidden;
      @include font(0, 0);

      &.large {
        overflow: auto;
        padding: 5px 0;
        border: none;
        max-width: 95px;
        max-height: 100%;
      }
    }

    &.attribute-color {
      .b-swatches-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-left: 1px;
        margin-right: 10px;
        border: 2px solid $color-none;

        &.selectable:hover,
        &.selected {
          border-color: $color-white;
          outline: 1px solid $color-grey;
        }
        &.large {
          margin: 0 5px 0 0;
          border-width: 0 0 2px;

          &.selectable:hover,
          &.selected {
            border-color: $color-grey;
            outline: none;
          }
        }
      }

      .label-name:after {
        content: ':';
      }

      .selected-value {
        display: inline;
        color: $color-grey;
        text-transform: capitalize;
      }
    }

    &.attribute-size {
      > .label {
        display: none;
      }

      .b-swatches {
        position: relative;
        display: inline-block;
        min-width: 200px;
        @include font(13px, false);
        line-height: 35px;
        .dialog-quickview & {
          min-width: 330px;
        }
      }

      .b-swatches-value {
        display: block;
        padding: 0 15px;
        font-weight: 400;
        border: 1px solid $color-grey_light3;
        background-color: $color-white;

        &:after {
          float: right;
          width: 35px;
          margin: 0 -15px 0 5px;
          border-left: 1px solid $color-grey_light3;
          @include font(4px, 0);
          text-align: center;
        }
      }

      .b-swatches-list {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        max-height: 200px;
        overflow: auto;
        margin: -1px 0;
        border: 1px solid $color-grey_light3;
        background-color: $color-white;
        z-index: $z-min + 1;

        &::-webkit-scrollbar {
          width: 20px;
          background-color: $color-grey_light2;
        }

        &::-webkit-scrollbar-thumb {
          width: 16px;
          background-color: $color-white-transparent;
          border: 4px solid $color-grey_light2;
          border-radius: 10px;
        }
      }

      .b-swatches-item {
        border-bottom: 1px solid $color-grey_light3;

        &:last-child {
          border-bottom: 0;
        }
      }

      .b-swatches-link {
        @include text-ellipsis;
        padding: 0 5px 0 15px;;
        text-transform: none;

        &.js-back_in_stock {
          cursor: pointer;
        }
      }

      .b-swatches-tooltip,
      .b-swatches-text {
        display: inline-block;
        padding: 0;
      }

      .b-swatches-tooltip {
        &:before {
          content: '-';
          margin: 0 5px;
        }
      }

      .expanded + .b-swatches-list {
        display: block;
      }

      .selected-text {
        color: $color-grey2;
      }

      .selectable .b-swatches-link:hover,
      .unselectable .js-back_in_stock:hover {
        background-color: $color-grey_light4;
      }

      .unselectable .b-swatches-text {
        text-decoration: line-through;
      }

      // B2B-specific styles
      .value.toggle-swatch {
        display: block;
      }
      > .label {
        display: block;
      }

      .label-name {
        display: inline-block;
        width: 20%;
      }

      .b-size_links-container {
        text-align: left;
        margin: 8px 0;
      }

      .error-message {
        margin-top: 5px;
      }

      .b-swatches {
        display: block;
        line-height: 1.2;
        max-width: none;

        &-value {
          display: none;
        }

        &-list {
          position: static;
          display: block;
          max-height: none;
        }

        &-item {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          padding: 5px 5px 5px 15px;

          &,
          .price-sales {
            @include font(13px, false);
          }
        }

        &-availability,
        &-price,
        &-text {
          display: inline-block;
          line-height: $input-height;
        }

        &-text {
          width: 20%;
          padding: 0;

          &:hover {
            background: none;
          }
        }

        &-availability {
          width: 10%;
          font-weight: 600;
          text-align: center;
        }

        &-price {
          width: 25%;
          text-align: right;
        }

        &-qty {
          float: right;
        }
      }
    }

  }
}
