%f-select,
.f-select,
%f-textarea,
.f-textarea,
%f-textinput,
.f-textinput {
	@include form-textinputs;
	@include transition(none);
	background-color: $color-form-bg;

	&:hover,
	&:focus {
		border-color: $color-form-border_hover;
	}
}

%f-select,
.f-select,
%f-textinput,
.f-textinput {
	height: $input-height;
}

@include placeholder {
	color: $color-form-border;
}

%f-select,
.f-select {
	@include form-select(false);
}

%f-textarea,
.f-textarea {
	@include form-textarea;
	
	height: 100px;
}

.f-radio,
.f-checkbox {
	@include form-checkbox;
	
	~ .f-label {
		&:before {
			content: icon-char(form-checkbox);
			@include icon-styles($font_size-small);
			color: $color-none;
		}

		&:hover:before {
			background-color: $color-form-bg_active;
		}
	}

	&:checked ~ .f-label:before {
		color: $color-grey;
	}

	&:disabled ~ .f-label {
		&:hover:before {
			background: none;
		}
	}
}

.f-radio {
	@include form-radio;
	
	~ .f-label:before {
		content: icon-char(form-radio);
	}
}