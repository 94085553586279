.b-title_box {
	@include flexbox();
	@include flex-column();
	background-color: $color-bg-title;
	background-image: url('../images/base64/title-bg.png');
	color: $color-white;
	text-align: center;
	
	&.m-bg {
		background-size: cover;
	}
	
	&-title {
		@include font(26px, 2px, $font-family_additional);
		font-weight: 300;
		text-transform: uppercase;
		margin: 30px 20px;
		@include order(2);
		
		// b2b styles
		font-size: 26px !important;
		text-align: center !important;
		padding: 0;

		@include respond-to(phone) {
			@include font(20px, false);
		}
	}

	&-subtitle {
		@include order(3);
		margin: -10px 0 30px;

		@include respond-to(phone) {
			display: none;
		}

		a {
			display: inline-block;
			padding: 0 10px;
			color: $color-white;
			letter-spacing: $letter_spacing-medium;
			line-height: 1;
			text-transform: uppercase;
			text-decoration: none;
			
			& + a {
				border-left: 2px solid $color-grey;
			}
		}
		
		p {
			color: $color-grey_light2;
			margin-top: 20px;
			
			@include respond-to(not-desktop) {
				display: none;
			}
		}
	}
}
