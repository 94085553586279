.dialog-back_in_stock {
	background-color: #fff !important;
}
.b-back_in_stock {
	padding: 15px 10px;
	tr {
		border-bottom: none;
	}
	&-description {
		p {
			margin-bottom: 8px;
		}
	}
	h4,
	&-title {// After form submit
		font-size: 1.8rem;
		font-weight: 600;
		margin-bottom: 15px;
		text-transform: none;
	}
	&-form,
	&-button {
		margin: 0 auto;
	}

	&-form {
		margin: 20px 0;
	}
	.f-label {
		display: none;
	}
	.f-textinput {
		max-width: 500px;
	}
	.f-field {
		margin-bottom: 15px;
	}
	&-button-container {
		text-align: center;
	}
	&-button {
		display: block;
		font-size: 16px !important;
	}
	// After form submit


	&-message {
		padding-top: 80px;
	}
	&-text {
		@include font(18px);
		display: block;
		.m-error & {
			color: $color-red;
		}
	}

}
// product details
.b-notifyme {
	&_img {
		margin: 0 !important;
	}
	&_product-name {
		font-size: 1.2rem;
		text-transform: uppercase;
		font-weight: 600;
	}
	&_product-sku {
		margin-bottom: 20px;
		.value {
			color: $color-grey2;
			font-size: 0.92308rem;
		}
		.label {
			display: none
		}
	}
	&_product-price {
		font-weight: 600;
		span {
			display: block;
		}
	}
	&_product-attributes,
	&_product-price {
		width: 49%;
		float: left;
	}
}
