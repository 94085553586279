@mixin minimizeHeader($speed: 0.1s) {
	.js-header_fixed {
		.b2b-site_header-wrapper,
		.b2b-cookies_message,
		.b2b-utility_menu,
		.b2b-header_tools .b2b-utility_menu-link,
		.b2b-primary_logo {
			@include transition(all, $speed, linear);
		}
	}

	.b2b-header-site_settings,
	.b2b-utility_menu {
		@include animation('animateOverflow 0.1s 0s 1 linear');

		.js-disable_header & {
			@include transition(none);
		}
	}

	.js-header_minimized {
		.b2b-header-site_settings,
		.b2b-cookies_message {
			max-height: 0;
			overflow: hidden;
		}

		.b2b-header-site_settings,
		.b2b-utility_menu {
			@include animation(none);
		}

		.b2b-utility_menu-popup.b2b-utility_menu-popup,
		.b2b-utility_popup.b2b-utility_popup {
			height: 0;
			overflow: hidden;
		}

		.b2b-header-promotion {
			max-height: none;
		}
	}
}

@include keyframes(animateOverflow) {
	0% {
		overflow: hidden;
	}
	100% {
		overflow: hidden;
	}
}

@include respond-to(desktop) {
	@include minimizeHeader;

	.js-header_minimized {
		.b2b-primary_logo {
			height: $logo-height_minimized;
		}

		.b2b-utility_menu-popup.b2b-utility_menu-popup,
		.b2b-utility_popup.b2b-utility_popup {
			height: auto;
		}

		.b2b-header_tools .b2b-utility_menu-link {
			line-height: $utility_menu-height_minimized;
		}
	}
}

@include respond-to(tablet) {
	@include minimizeHeader(0.2s);

	.js-header_minimized {
		.b2b-utility_menu {
			max-height: none;
		}
	}
}

@include respond-to(phone) {
	@include minimizeHeader(0.2s);
	.b2b-utility_menu {
		max-height: $utility_menu-height_mobile;
	}

	.js-header_minimized {
		.b2b-utility_menu {
			max-height: none;
			overflow: hidden;
		}
	}
}

