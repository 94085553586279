// for styleguide styles only
.sg-hide_code {
	margin-bottom: 3em;
	
	pre {
		display: none;
	}
}

//color palette
.sg-color_item {
	border: 2px solid $color-black;
	display: inline-block;
	font-size: 12px;
	line-height: 1.5;
	margin: 5px;
	padding: 10px;
	text-align: center;
	vertical-align: middle;
	width: 130px;
	color: $color-black;
	
	&.sg-color_white {
		color: $color-white;
	}
}

//form elements

.sg-form_elements-wrapper {
	background: $color-white;
	padding: 40px;
}

//icons

.sg-icon {
	display: inline-block;
	margin: 5px;
	
	&:before {
		color: $color-text;
		font-size: 40px;
	}
}

//buttons

.sg-styleguide_buttons {
	width: 540px;
}

//CLP Banners

.sg-banner {
	background: $color-black;
	height: 300px;
	position: relative;
}