form, 
input,
.f-field,
.b-site_header > div,
.b-header-logo_wrapper > div,
.b-header-logo_link:before,
.primary_logo a:before,
.b-site_header nav,
.b-title_box,
.b-secondary,
.b-footer {
	display: none !important;
}

.b-site_header-placeholder,
.b-site_header-wrapper {
	position: static !important;
	height: auto !important;
}

.b-site_header {
	position: relative !important;
	height: 0;
	margin-bottom: 40px;
	border-bottom: $logo-height_print solid $color-header;
	
	.b-header-logo_wrapper,
	.b-header-logo,
	.primary_logo {
		display: block !important;
	}
	
	.b-header-logo,
	.primary_logo {
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		background: none;
	}
}

.b-content {
	max-width: none !important;
	width: auto !important;
}

.b-primary {
	padding: 0;
}

.toggle {
	&:after {
		display: none !important;
	}
	
	&-content {
		display: block !important;
	}
	
	&-tab,
	&-line {
		margin-bottom: 30px;
		padding: 0;
		border: 0;
	}
}


// Store Locator
.b-store_locator {
	&-search,
	&-details_back,
	&-details_actions,
	.b-store-link,
	.b-store_result-actions,
	.b-direction-title,
	.b-direction-form,
	.b-direction-back {
		display: none !important;
	}
	
	&-details_info,
	&-details_map_direction {
		display: block !important;
	}
	
	&-results_map,
	&-details_map {
		float: none;
		width: 100%;
		margin: 20px 0;
		page-break-inside: avoid;
	}
	
	&-details_map {
		height: 400px;
	}
	
	&-map {
		border: 0 !important;
		zoom: 1;
	}
	
	&-details {
		.b-store-image {
			max-width: none !important;
			width: 100% !important;
			margin: 0 0 20px !important;
		}
		
		.b-store-info,
		.b-store-hours,
		.b-store-events {
			float: left;
			width: 50% !important;
			padding: 0 40px 20px 0 !important;
		}
	}
}
