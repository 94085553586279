%bg-lion {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: url(logo-lion.png);
}

%bg-tiger {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: url(logo-tiger.png);
}

%b2b-loader {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 125px auto;
    background-image: url(b2b-loader.gif);
}
