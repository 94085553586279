@include form-elements_base(
	$field_indent: 15px,
	$caption-font_size: 11px
);

.f-label {
	margin-bottom: 5px;
	@include font(13px, 0.25px);
	font-weight: 600;
	color: $color-grey;
}

.required-indicator,
.f-required-indicator {
	&,
	&:before {
		color: $color-grey2;
	}
}

.f-form-custom {
	@include respond-to(not-phone) {
		.f-form-columns {
			position: relative;
			margin-right: -100px;

			&:before {
				content: '';
				position: absolute;
				top: 0; bottom: 15px;
				right: 50%;
				padding-right: 50px;
				border-left: 1px solid $color-form-border;
			}

			> .f-field {
				padding-right: 100px;
			}

			.f-field-salutation {
				padding-right: 20px;
			}
		}

		.f-form-action {
			float: right;
			width: 50%;
			padding-left: 50px;
		}

		.b-button {
			width: 100%;
		}
	}

	.f-form-action {
		@include flexbox;
		@include flex-column;
	}

	.f-description {
		@include order(1);
	}
}
