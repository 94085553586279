.b-order {
	&-search {
		padding: 25px;
		margin-bottom: 5px;
		background: $color-white;

		@include respond-to(not-phone) {
			@include flexbox;
			@include jc-space-between;

			&-input {
				@include flex(auto);
				margin-right: 20px;
			}

			&-button {
				@include flex(none);
				width: 180px;
				padding-right: 10px;
				padding-left: 10px;
			}
		}

		@include respond-to(phone) {
			&-input,
			&-button {
				width: 100%;
			}

			&-input {
				margin-bottom: 20px;
			}
		}
	}

	&-shipment {
		.line-item-details {
			width: 47%;
		}
		
		.line-item-quantity {
			width: 17%;
		}
		
		.line-item-price {
			width: 19%;
		}
	}
}

.b-orders {
	&-item_info {
		width: 100%;
		padding-right: 10px;
		padding-left: 10px;
	}
}
