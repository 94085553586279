.b-text,
%b-text {
	@include font($font_size-small, false, $font_family-additional);
	h3 {
		@include font(22px, false, $font_family);
	}

	h4 {
		@include font(14px, false, $font_family);
	}
}
