// Reset
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, button,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}


// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}


// Responsive images and fix for extra-space after image
img {
	max-width: 100%;
	height: auto;
	border: none;
	outline: none;
	vertical-align: top;
}


// Document layout
* {
	box-sizing: border-box;
	word-wrap: break-word;
}

// Remove X-button from input fields in IE and Edge
::-ms-clear {
	display: none;
}

input,
select,
textarea {
	border-radius: 0;
}


// Reset extra padding in Firefox
input[type="button"],
input[type="submit"],
input[type="reset"],
button {
	outline: 0;
	cursor: pointer;
	
	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
}


// Reset list styles
ul,
li {
	list-style-type: none;
}
