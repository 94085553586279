// TODO: refactor
// + find @media in the project
@mixin respond-to($media) {
  @if $media == not-desktop {
    @media only screen and (max-width: $max-tablet-width) {
      @content
    }
  } @else if $media == phone {
    @media only screen and (max-width: $max-phone-width) {
      @content
    }
  } @else if $media == phone-mini {
    @media only screen and (max-width: $width-mobile) {
      @content
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $width-tablet) and (max-width: $max-tablet-width) {
      @content
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $max-tablet-width) {
      @content
    }
  } @else if $media == desktop-small {
    @media only screen and (min-width: $max-tablet-width + 1) and (max-width: $width-desktop) {
      @content
    }
  } @else if $media == not-phone {
    @media only screen and (min-width: $max-phone-width + 1) {
      @content
    }
  }

  // New media - TODO: refactor
  // max 767px
  @else if $media == max-phone {
    @media only screen and (max-width: $max-phone-width) {
      @content
    }
  } 
  // max 1023px
  @else if $media == max-tablet {
    @media only screen and (max-width: $max-tablet-width) {
      @content
    }
  } 
  // max 1919px
  @else if $media == max-small-desktop {
    @media only screen and (max-width: $max-small-desktop-width) {
      @content
    }
  } 
  // min 768px
  @else if $media == min-tablet {
    @media only screen and (min-width: $width-tablet) {
      @content
    }
  } 
  // min 1024px
  @else if $media == min-small-desktop {
    @media only screen and (min-width: $width-desktop_small) {
      @content
    }
  } @else {
    @warn 'Incorrect @media: #{$media}';
  }
}
