.b-timer {
	@include user-select(none);
	@include font(40px, false);
	line-height: 0.9em;
	text-align: center;
	cursor: default;
	
	@include respond-to(tablet) {
		@include font(34px);
	}
	
	@include respond-to(phone) {
		@include font(28px);
	}
	
	&-item {
		position: relative;
		display: inline-block;
		vertical-align: top;
	}
	
	&-item + &-item {
		margin-left: 0.5em;
		padding-left: 0.5em;
		
		&:before {
			content: ':';
			position: absolute;
			left: -0.25em; bottom: 0;
			font-size: 0.9em;
			opacity: 0.6;
		}
	}
	
	&-label {
		margin-bottom: 1em;
		font-size: 0.25em;
		line-height: 1;
		text-transform: uppercase;
		opacity: 0.6;
	}
}
