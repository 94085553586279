@mixin b-coupon_code {
	.f-label {
		margin-bottom: 10px;
	}
	
	.f-field {
		@include flexbox;
		margin-bottom: 0;
	}
	
	.f-textinput {
		@include flex(1);
	}
	
	.f-textinput,
	.b-button {
		@include text-ellipsis;
	}
	
	.b-button {
		margin: 0 -1px;
		padding-left: 5px;
		padding-right: 5px;
		min-width: 70px;
		
		&:hover {
			opacity: 0.7;
		}
	}
}
