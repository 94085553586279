@include dialog;

.ui-widget-overlay {
	opacity: 0.95;
}

%dialog-title {
	@extend %heading-2;
	margin: 0 25px 20px;
	text-align: center;
}
