.cup-sms {
	top: 100px;
	&-popup{
		display: none;

		&.active {
			height: 100vh;
			display: block;
			.b-utility_popup-content {
				height: 100vh;
			}
			.cup-sms-popup-item {
				height: 100vh;
			}
		}

		&_container {
			margin: auto;
			max-width: 360px;
			text-align: left;
			margin-top: 36px;
		}
	}
	.b-utility_popup-close {
		z-index: 10;
	}
	.b-button {
		width: 100%;
	}
	@include respond-to(not-desktop) {
		&.b-utility_popup {
			top: 64px;
		}
	}
	@include respond-to(phone) {
		.b-checkout-actions .b-button {
			max-width: 100%;
		}
	}
}