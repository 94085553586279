// Text Colors
.m-color {
	&-black {
		color: $color-black;
	}

	&-white {
		color: $color-white;
	}

	&-grey {
		color: $color-grey;
	}

	&-grey_light {
		color: $color-grey_light;
	}

	&-red {
		color: $color-red;
	}

	&-green {
		color: $color-green;
	}

	&-blue {
		color: $color-blue;
	}

	&-yellow {
		color: $color-yellow;
	}
}

.m-bg {
	&-black {
		background-color: $color-black;
	}

	&-white {
		background-color: $color-white;
	}

	&-grey {
		background-color: $color-grey;
	}

	&-grey_light {
		color: $color-grey_light;
	}

	&-red {
		background-color: $color-red;
	}

	&-green {
		background-color: $color-green;
	}

	&-blue {
		background-color: $color-blue;
	}

	&-yellow {
		background-color: $color-yellow;
	}
}
// Icons color
.fa-white {
    color: $color-white;
}
