// Buttons

/*
---
name: Buttons
tag: buttons
category: form elements
---

```html
	<div class="sg-styleguide_buttons">
		<button class="b-button">Button Default</button>
		</br></br>
		<button class="b-button m-black">Button Black</button>
		</br></br>
		<button class="b-button m-grey">Button Grey</button>
		</br></br>
		<button class="b-button m-light_grey">Button Light Grey</button>
		</br></br>
		<button class="b-button m-white">Button White</button>
		</br></br>
		<button class="b-button m-transparent">Button Transparent</button>
		</br></br>
		<button class="b-button m-text">Button Text</button>
	</div>
```
*/

@mixin b-button-disabled($bg_color) {
	opacity: 0.5;
	background-color: $bg_color;
}

@mixin b-button(
	$font-small: 13px / 17px $font_family,
	$font-large: 14px / 18px $font-family,
	$letter-spacing_small: $letter-spacing,
	$letter-spacing_large: $letter-spacing
	) {
	@include transition;
	display: inline-block;
	padding: ($input-height - 19px) / 2 40px;
	border: 1px solid;
	outline: 0;
	font: $font-small;
	letter-spacing: $letter-spacing_small;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	-webkit-appearance: none;
	
	cursor: pointer;

	// large
	&.m-large {
		font: $font-large;
		letter-spacing: $letter-spacing_large;
		padding: ($input-height-large - 20px) / 2 40px;
	}
	
	// small
	&.m-small {
		padding-left: 20px;
		padding-right: 20px;
	}
}


@mixin b-button-states(
	$bg_color: $color-black,
	$border_color: $color-none,
	$text_color: $color-white,
	
	$bg_color-hover: false,
	$border_color-hover: false,
	$text_color-hover: false
) {
	background: $bg_color;
	border-color: $border_color;
	
	&,
	&:visited {
		color: $text_color;
	}
	
	&:hover,
	&:active {
		@if ($bg_color-hover) {
			background-color: $bg_color-hover;
		}
		@if ($border_color-hover) {
			border-color: $border_color-hover;
		}
		@if ($text_color-hover) {
			color: $text_color-hover;
		}
	}
	
	&:disabled,
	&:disabled:hover,
	&:disabled:active {
		@include b-button-disabled($bg_color);
		cursor: default;
	}
}


@mixin b-button-text(
	$color: $color-text_button,
	$font: 11px/1.2 $font_family
	) {
	@include transition;
	cursor: pointer;
	outline: 0;
	padding: 0;
	border: 0;
	color: lighten($color, 40%);
	background: none;
	font: $font;
	letter-spacing: $letter_spacing;
	text-align: left;
	text-decoration: underline;
	text-transform: none;

	// hover
	&:hover {
		background: none;
		text-decoration: none;
	}

	// disabled
	&:disabled {
		background: lighten($color, 10%);
	}

	& .b-button-inner,
	&:visited .b-button-inner {
		color: $color;
		pointer-events: none;
	}
}

