.pdp-main {
	position: relative;
}

.b-pdp {
	&-product_view {
		@include clearfix;
		padding: 0 $gutter-pdp;
		
		@include respond-to(phone) {
			padding: $gutter-global;
		}
	}
	
	&-product_info_wrapper {
		@include respond-to(desktop) {
			float: right;
			width: 35%;
			padding-left: $gutter-pdp;
		}
		
		@include respond-to(tablet) {
			float: right;
			width: 45%;
		}
		
		@include respond-to(phone) {
			width: 100%;
		}
	}
	
	&-product_info {
		position: relative;
		padding-top: $gutter-pdp;
		z-index: $z-pdp-info;
		
		.availability-instore,
		.availability-web {
			margin-bottom: 15px;
		}
		
		.inventory {
			display: none;
		}
		
		.product-main-attributes {
				margin: 5px 0;
				
				.value {
					font-weight: 700;
				}
		}
		
		.product-options {
			width: 100%;
			margin-bottom: 15px;
			
			.input-select {
				@extend %f-select;
				width: 100%;
			}
		}
	}
	
	&-product_number,
	.product-main-attributes {
		color: $color-grey2;
		@include font($font_size-small, false);
		text-transform: uppercase;
		
		@include respond-to(phone) {
			@include order(1);
		}
	}
	
	&-product_number {
		margin-bottom: 25px;
	}
	
	&-general_info {
		margin-bottom: 25px;
		
		@include respond-to(phone) {
			@include flexbox;
			@include flex-column;
		}
	}

	&-social {
		@include respond-to(not-phone) {
			float: right;
			position: relative;
			z-index: $z-min;
		}
		
		@include respond-to(phone) {
			@include order(3);
			margin-top: 10px;
		}
	}
	
	&-product_name {
		margin-bottom: 5px;
		@include font(26px, $letter_spacing-medium, $font_family-additional);
		line-height: 1;
		text-transform: uppercase;
	}
	
	&-pricing {
		text-transform: uppercase;
		
		.promotion,
		.product-price {
			@include respond-to(desktop) {
				display: inline-block;
			}
		}
		
		.product-price {
			@include respond-to(desktop) {
				display: inline;
				margin-right: 10px;
			}
		}
		
		.product-price > div,
		.salesprice,
		.price-sales {
			@include respond-to(desktop) {
				float: left;
			}
		}
		
		.price-tiered {
			margin: 5px 0;
			@include font($font_size-small, false);
			color: $color-red;
			
			@include respond-to(desktop) {
				float: left;
				clear: both;
			}
			
			&-values {
				color: $color-text;
			}
		}
		
		.price-standard {
			float: left;
			
			&:after {
				content: "|";
				display: inline-block;
				margin: 0 10px;
			}
		}

		.product-price,
		.price-sales {
			@include font(15px, false, $font_family);
			font-weight: 600;
		}
		
		.promotion,
		.price-standard {
			@include font(15px, false);
		}
		
		.promotion {
			text-transform: none;
			
			@include respond-to(not-desktop) {
				margin-top: 5px;
			}
			
			&-title {
				display: none;
			}
			
			.callout-message ~ .callout-message {
				@include respond-to(desktop) {
					margin-left: 5px;
				}
			}
			
			.b-tooltip-link {
				@include font($font_size-small, false);
				color: $color-grey2;
			}
		}
		
		@include respond-to(phone) {
			@include order(1);
		}
	}

	&-product_additional_block {
		clear: both;
	}
}
