@include pdp-product_zoom;

.b-zoom-link {
	&:before {
		content: none;
	}
}
@include respond-to(tablet) {
	.ui-dialog-close_overlay {
		height: auto;
		width: auto;
	}
}
