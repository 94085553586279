.b-wechat {
	text-align: center;

	@include respond-to(desktop) {
		margin-top: 50px;
	}

	&-qrcode {
		width: 60%;
		max-width: 290px;
		margin: 0 auto;
	}

	&-title {
		@include font(30px);

		font-weight: 600;
		text-transform: uppercase;
		margin-top: 40px;

		@include respond-to(phone) {
			@include font(24px);

			margin-top: 20px;
		}
	}

	&-subtitle {
		@include font(20px);

		margin-top: 10px;

		@include respond-to(phone) {
			@include font(16px);
		}

		&.m-error {
			color: $color-red;
		}
	}
}

.wechat {
	top: 100px;
	&-popup{
		display: none;

		&.active {
			height: 100vh;
			display: block;
			.b-utility_popup-content {
				height: 100vh;
			}
			.wechat-popup-item {
				height: 100vh;
			}
		}

		&_container {
			margin: auto;
			max-width: 360px;
			text-align: left;
			margin-top: 36px;
		}
	}
	.b-utility_popup-close {
		z-index: 10;
	}

	@include respond-to(not-desktop) {
		&.b-utility_popup {
			top: 64px;
		}
	}

}
