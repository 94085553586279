.dialog-color_selection {
	max-width: 720px;
}

.b-color_selection {
	display: inline-block;
	vertical-align: top;
	$cs-width: 200px;
	
	&-label {
		float: left;
		
		.b-pdp-attribute.attribute-color & {
			margin-bottom: 0;
		}
	}
	
	&-value {
		text-align: right;
	}
	
	&-link {
		float: right;
		line-height: 1.5;
		text-decoration: none;
		color: $color-grey_light;
		
		&:hover {
			text-decoration: underline;
		}
		
		.b-pdp-attribute.attribute-color & {
			border: 0;
			outline: 0;
		}
		
		.b-swatches-image {
			float: right;
			box-sizing: content-box;
			margin-left: 10px;
			border: 1px solid $color-white;
			outline: 1px solid $color-grey;
		}
		
		.b-swatches-text {
			display: none;
		}
		
		.b-swatches-count {
			overflow: hidden;
			@include font(12px);
			color: $color-grey;
		}
	}
	
	&-list_wrapper {
		overflow: hidden;
		text-align: center;
		
		.owl-counter {
			margin: 0 auto 15px;
			@include user-select(none);
			@include font;
			color: $color-grey;
		}
		
		.owl-total {
			&:before {
				content: '/';
				margin: 0 5px;
			}
		}
	}
	
	&-list {
		overflow: visible;
		width: 70%;
		margin: 0 auto 10px;
		@include font(0, 0);
		
		@include respond-to(not-phone) {
			width: percentage(1/3);
			
			.owl-stage-outer {
				overflow: visible;
			}
		}
		
		.owl-prev,
		.owl-next {
			margin: 0 -23%;
			width: 23%;
			
			@include respond-to(not-phone) {
				margin: 0 -101%;
				width: 100%;
				box-shadow: 200px 0 100px -100px $color-white inset;
			}
			
			&:before {
				color: $color-black;
				text-shadow: 0 0 10px $color-white;
			}
		}
		
		.owl-prev {
			@include respond-to(not-phone) {
				box-shadow: 240px 0 100px -100px $color-white inset;
			}
		}
			
		.owl-next {
			@include respond-to(not-phone) {
				box-shadow: -240px 0 100px -100px $color-white inset;
			}
		}
	}
	
	&-item {
		width: 100%;
		padding: 0 10px;
		@include font;
		text-align: center;
			
		@include respond-to(not-phone) {
			padding: 0 20px;
		}
	}
	
	&-anchor {
		text-decoration: none;
	}
	
	&-image {
		margin-bottom: 15px;
	}
	
	&-name,
	&-swatch {
		@include transition(opacity);
		opacity: 0;
		text-transform: uppercase;
			
		.active & {
			opacity: 1;
		}
		
		@include respond-to(not-phone) {
			margin-left: -50px;
			margin-right: -50px;
		}
	}
	
	&-name {
		@include font(16px, false);
		line-height: 1.2;
		font-weight: 600;
	}
	
	&-swatch {
		margin-top: 5px;
	}
}
