//No Search Results

.b-no_hits {
	&-title {
		@extend %heading-2;
	}
	
	&-search_term {
		@include font($font_size-small, false, $font_family);
	}

	&-search_tips {
		@include font(11px, false, $font_family-additional);
	}
}
