@mixin checkout-base {
	.p-checkout {
		#wrapper {
			@include flexbox;
			@include flex-column;
			min-height: 100vh;
		}
		
		#main {
			@include flex(auto);
		}

		.ie & {
			@include flexbox;

			#wrapper {
				@include flex(1);
			}
		}
	}
	
	.b-checkout-select_address {
		@include respond-to(not-phone) {
			width: 50%;
			padding-right: 10px;
		}
		
		.input-select {
			@extend %f-select;
			width: 100%;
		}
	}
	
	.f-field-button_select {
		display: none;
	}
	// address warnings
	.address-warning {
		padding: 15px 0;
		color: #0E8F1B;
		opacity: .7;
		transition: opacity .2s ease-in;
		button[name$='shippingAddress_save']:hover + & {
			opacity: 1;
		}
	}
	.f-label[for$='_addressFields_address1']{
		position: relative;
		.address-pls-select {
			color: forestgreen;
			position: absolute;
			top: -20px;
			font-size: 11px;
			white-space: nowrap;
			@include respond-to(phone) {
				top: -23px;
				white-space: normal;
			}
		}
	}
}