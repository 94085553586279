%bg-pp {
	background-repeat: no-repeat;
	background-position: 50% 50% !important;
	background-size: 125px auto !important;
}

%bg-world {
	background-repeat: no-repeat;
	background-position: 50% 50% !important;
	background-size: 65px auto !important;
}

%bg-philippplein {
	background-repeat: no-repeat;
	background-position: 50% 50% !important;
	background-size: 65px auto !important;
	background-image: url('../images/base64/philipp_plein.png');
}

%bg-360view {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 40px auto;
}

%bg-infinite-loader {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 55px auto;
}
