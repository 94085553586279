.b-pdp {
  &-promo_additional_block {
    padding: 4px 0;
    margin: 8px 0;
    font-size: 10px;
    color: $color-grey2;
  }
  &-promotion {
    @include respond-to(phone) {
      margin: 10px 0;
    }
    &_item {
      margin: 8px 0;
    }
    .promotion-callout {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .callout-message {
      display: block;
      padding: 4px 12px;
      margin: 5px 0;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
    .b-tooltip-link {
      margin-left: 5px;
      @include font(9px, false);
      line-height: 1.1;
      color: $color-grey2;
      text-transform: uppercase;
      text-decoration: none;
    }
    .icon-info {
      margin-left: 7px;
      &:before {
        color: $color-grey2;
        font-size: 12px;
      }
    }

  }
}
