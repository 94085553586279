.b-return {
	&-item {
		&_col {
			@include font($font_size-small, false, $font_family-additional);
		}

		.label {
			@include font($font_size, false, $font-family);
		}
	}

	&-not_found_message {
		font-family: $font_family-additional;

		.b-return_back + & {
			font-family: $font_family;
		}
	}
}
