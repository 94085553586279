// Recommendations on PDP

$transition: opacity .3s ease-in;
.b-pdp {
  &-bottom {
    margin: 30px 20px 40px;
    padding-top: 10px;
    border-top: 1px solid $pdp-border-color;
  }
  
  &-recomm {
    &_wrapper {
      .js-tab-content {
        opacity: 0;
        visibility: hidden;
        height: 0;
        transition: $transition;
        &--active {
          opacity: 1;
          visibility: visible;
          height: auto;
        }
      }
      .js-einstein-title,
      .js-slot-title {
        display: none
      }
    }
    &_buttons {
      opacity: 0; // revealed by js upon loading
      height: 0;
      display: flex;
      justify-content: center;
      gap: 30px;
      transition: $transition;
    }
    &_button {
      background-color: transparent;
      padding: 15px 0 3px;
      color: $color-grey;
      font-size: calc(12px + (23 - 12) * ((100vw - 320px) / (1900 - 320)));
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.2s;
      border-bottom: 1px dashed $color-grey;
      &:hover {
        color: $color-black;
        border-color: $color-black;
      }
      &.js-tab-button-recomm { // revealed by js upon loading
        display: none;
        opacity: 0;
        transition: $transition;
      }
      &.js-tab-button--active {
        color: $color-black;
        border-bottom: 1px solid $color-black;
        pointer-events: none;
        cursor: default;
        transition: $transition;
      }
    }
  }
  
}

// Recommendations & last visited products on PDP & PLP
.b-product_carousel {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  animation: fade 2s ease forwards;
  width: 100%;
  @include respond-to(max-tablet){
      padding: 0;
    }
  &-title {
    @extend %heading-2;
    color: $color-text_light;
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: center;
    @include respond-to(phone) {
      @include font(16px, false);
    }
  }
  
  // Owl overrides 
  // see also _core_UI/cartridge/scss/default/lib/components/_carousel.scss
  .owl-carousel {
    position: static;
    transition: opacity .5s ease-in;
    opacity: 0.05;
    &.owl-loaded {
      opacity: 1;
    }
  }
  
  .owl-item {
    display: flex;
    justify-content: center;
    .b-product_tile {
      max-width: 230px;
    }
  }
  .owl-prev, .owl-next {
    width: 40px;
    &:before {
      font-size: 2rem;
      text-shadow: none;
    }
  }
  .owl-dot {
    width: 10px;
    height: 10px;
    margin-top: 10px;
    border-radius: 50%;
  }
}


