.m {
	&-relative { position: relative; }
	
	// Alignment
	&-left  { float: left; }
	&-right { float: right; }
	
	// Text alignment
	&-text-left   { text-align: left; }
	&-text-left_mobile { 
		@include respond-to(phone) {
			text-align: left;
		}
	}
	&-text-center { text-align: center; }
	&-text-center_mobile {
		@include respond-to(phone) {
			text-align: center;
		}
	}
	&-text-right  { text-align: right; }
	&-text-right_mobile  {
		@include respond-to(phone) {
			 text-align: right; 
		}
	}
	
	&-nowrap { white-space: nowrap; }
	
	// Text size
	&-text-normal { font-size: $font_size; }
	&-text-small  { font-size: $font_size-small; }
	
	// Vertical alignment
	&-va-top    { vertical-align: top; }
	&-va-middle { vertical-align: middle; }
	&-va-bottom { vertical-align: bottom; }
	
	// Vertical absolute alignment
	&-abs-top {
		position: absolute;
		top: 0; bottom: auto;
		left: 0; right: 0;
	}
	&-abs-top_mobile {
		@include respond-to(phone) {
			position: absolute;
			top: 0; bottom: auto;
			left: 0; right: 0;
		}
	}
	&-abs-bottom {
		position: absolute;
		top: auto; bottom: 0;
		left: 0; right: 0;
	}
	&-abs-bottom_mobile {
		@include respond-to(phone) {
			position: absolute;
			top: auto; bottom: 0;
			left: 0; right: 0;
		}
	}
	&-abs-middle {
		position: absolute;
		top: 50%; bottom: auto;
		left: 0; right: 0;
		@include transform(translate(0, -50%));
	}
	&-abs-middle_mobile {
		@include respond-to(phone) {
			position: absolute;
			top: 50%; bottom: auto;
			left: 0; right: 0;
			@include transform(translate(0, -50%));
		}
	}
	
	// Display type
	&-inline_block { display: inline-block; }
	&-block        { display: block; }
	&-inline       { display: inline; }
	
	&-bordered_block {
		padding: 15px 20px;
		border: 1px solid $color-grey_light2;
	}

	&-padding_block {
		padding: $gutter-global;
	}
	
	&-clear_left {
		clear: left;
	}
	
	&-clear_right {
		clear: right;
	}
}

@for $i from 1 through 6 {
	.m-order_mobile-#{$i} {
		@include respond-to(phone) {
			@include order($i);
		}
	}
}
