.b-checkout-primary,
.b-checkout-secondary {
	margin-top: 40px;
	
	.checkout-progress-indicator ~ {
		margin-top: 0;
	}
}

.b-checkout-title,
.b-checkout-shipping_comment .f-label {
	@include respond-to(phone) {
		text-align: left;
	}
}

.b-payment_method-icon:before {
	content: none;
}
