@mixin mobile-nav_menu-link($link-color: $color-grey_light) {
	display: block;
	padding: ($navigation_item-height_mobile - 20px) / 2 20px;
	font-size: $font_size;
	line-height: 20px;
	color: $link-color;
	text-decoration: none;
}

@mixin mobile-nav_menu-title() {
	font-size: 16px;
	text-transform: uppercase;
}

@mixin mobile-nav_menu(
	$mobile-border_color: $color-black3,
	$mobile-item_seleftor: 'li'
) {
	border-bottom: 1px solid $mobile-border_color;
	color: $color-grey_light;

	a {
		@include mobile-nav_menu-link();
	}

	#{$mobile-item_seleftor} {
		position: relative;
		display: block;
		border-top: 1px solid $mobile-border_color;
	}
}

@mixin mobile-nav_submenu($bg-color: $color-black2, $header-navigation: false) {
	@if ($header-navigation) {
		display: none;
	} @else {
		@include transition(max-height);
		max-height: 0;
		overflow: hidden;

		.active > & {
			max-height: 600px;
		}
	}

	background-color: $bg-color;

	a {
		padding-left: 35px;

		&:active {
			background-color: lighten($color-black2, 5%);
		}
	}
}

@mixin main-navigation(
	$mobile-bg_color: $color-header,
	$mobile-border_color: $color-black3
) {
	.b2b-navigation-label {
		vertical-align: super;
		color: $color-category-label;
		font-size: 0.6em;
		text-transform: uppercase;
	}

	.b2b-navigation-level_3 .b2b-navigation-banner {
		display: none;
	}

	@include respond-to(desktop) {
		.b2b-navigation {
			position: relative;
			z-index: $z-navigation;
			@include font(0, 0);

			&-link.disabled,
			&-top_link.disabled,
			&-top_item.disabled {
				pointer-events: none;
			}
			.menu-item-toggle {
				display: none;
			}

			&-top_item {
				display: inline-block;
				vertical-align: top;
				@include font;
				line-height: 1;
			}

			&-top_link {
				position: relative;
				display: block;
				text-decoration: none;
			}

			&-dropdown {
				display: none;
				position: absolute;
				top: 100%; left: 0;
				width: 100%;
				background-color: $color-grey_light2;
			}

			&-top_item:hover &-dropdown {
			display: block;
			}

			&-banner,
			&-level_2,
			.m-horizontal > &-column {
				display: inline-block;
				vertical-align: top;
				text-align: left;
			}


			.m-horizontal {
				@include font(0,0);

				& > .b2b-navigation-column {
					@include font();
				}
			}

			&-level_2.m-horizontal > &-column,
			&-level_2.m-vertical {
				width: 180px;
				padding-right: 25px;
			}

			.two-columns {
				column-count: 2;
				column-gap: 30px;
				min-width: 300px;
			}

			&-item,
			&-banner {
				margin-top: 10px;
			}

			&-link {
				text-decoration: none;
			}

			&-banner {
				.banner-item {
					display: inline-block;
					vertical-align: top;
				}
			}
		}
	}


	@include respond-to(not-desktop) {
		.b2b-main_navigation {
			display: none;
			position: absolute;
			top: 100%; left: 0;
			width: 100%;
			background-color: $mobile-bg_color;
		}

		.b2b-navigation {
			@include mobile-nav_menu($mobile-border_color, '.b2b-navigation-item, .b2b-navigation-top_item');

			&-top_link {
				@include mobile-nav_menu-title;
			}

			&-dropdown {
				@include mobile-nav_submenu($header-navigation: true);
			}

			&-level_3 {
				@include mobile-nav_submenu($mobile-border_color, true);

				.m-level_3 {
					padding-left: 60px;
				}
			}

			&-banner {
				display: none;
			}


			&-bottom {
				@include flexbox;
			}

			&-bottom_item {
				min-width: 40%;
				background: none;

				&:first-child {
					@include flex-grow(1);
					border-right: 1px solid $mobile-border_color;
				}
			}

			&-bottom_link {
				@include mobile-nav_menu-link;
				text-transform: uppercase;

				&.f-select {
					width: 100%;
					height: auto;
					border: 0;
					box-shadow: none;
					background-color: transparent;
					color: $color-grey_light;
				}
			}
		}

		.menu-item-toggle {
			position: absolute;
			top: 0; right: 0;
			left: 0;
			padding: 0 15px;
			line-height: $navigation_item-height_mobile;
			text-align: right;

			@include icon(plus, before, false);

			&:before {
				line-height: 1;
			}

			&.active {
				left: auto;

				&:before {
					content: icon-char(minus);
				}
			}
		}
	}


	@include respond-to(tablet) {
		.b2b-main_navigation {
			&.b2b-main_navigation {
				width: 48%;
			}
		}
	}
}
