@mixin loader-image() {
	// Reserved for loader styles on brand level
}

@mixin loader(
	$bg-color: fade-out($color-white, 0.25),
	$use-animation: true
) {
	.js-loading:before,
	.js-loading:after,
	.loader {
		position: absolute;
		z-index: $z-loader;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
	}
	
	.js-loading:before,
	.loader {
		background: $bg-color;
	}
	
	.js-loading:after,
	.loader-indicator {
		display: block;
		width: 100%;
		height: 100%;
		@include loader-image;
		@if $use-animation {
			@include animation(loader 2s infinite ease-in-out);
		}
		
		.desktop & {
			background-attachment: fixed;
		}
	}
	
	.js-loading:before,
	.js-loading:after {
		content: '';
	}
	
	@include keyframes(loader) {
		50% { opacity: 0.4; }
	}
}

@mixin loader-iframe(
	$use-animation: true
) {
	.js-iframe_loading {
		position: relative;
		
		&:before {
			content: '';
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			max-height: 100vh;
			@include loader-image;
			@if $use-animation {
				@include animation(loader 2s infinite ease-in-out);
			}
		}
		
		iframe {
			opacity: 0;
			pointer-events: none;
			@include calc(max-height, '100vh - 40px');
		}
	}
}
