.b-store_avilability {

	&-title {
		@extend %dialog-title;
	}

	&-product {
		margin-bottom: 20px;
	}

	.b-pdp-image_wrapper {
		float: left;
		width: 25%;
		margin: 0 5% 0 0;
		padding: 0;

		@include respond-to(phone) {
			display: none;
		}
	}

	.b-pdp-variations {
		@include respond-to(not-phone) {
			display: inline-block;
			vertical-align: top;
			width: 70%;
		}
	}

	.attribute-size .b-swatches-list {
		max-height: 135px;
		}

		.b-store-link {
		display: none;
	}

	&-product_name {
		margin-bottom: 0;
	}

	&-product_number {
		margin-bottom: 10px;
		@include font($font_size-small, false);
		color: $color-grey2;
		text-transform: uppercase;
	}

	&-product_price {
		overflow: hidden;
		margin: 0 0 20px;
		padding: 0;
		border: 0;
	}

	&-search_form {
		@include respond-to(not-phone) {
			@include flexbox;
			@include ai-end;


			.f-field-select {
				@include flexbox;
				@include jc-center;
				padding: 10px 0;
				background: $color-grey_light3;
				width: 100%;
			}
			.f-label {
				margin-right: 10px;
			}
			.f-label-value {
				line-height: 35px;
			}


		}
	}

	&-no-results {
		text-align: center;
	}

	&-no-results-text {
		font-weight: 700;
	}

	&-no-results-button {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	&-no-results-guaranteed {
		font-size: 11px;
		font-weight: 300;
	}

	&-search_result {
		margin-top: 20px;
	}

	&-search_map {
		width: 100%;
		height: 300px;

		@include respond-to(not-phone) {
			float: right;
			width: 50%;
			height: 400px;
		}
	}

	&-stores {
		@include respond-to(not-phone) {
			@include flexbox;
			@include flex-column;
			float: left;
			width: 50%;
			height: 400px;
			padding-right: 20px;

			.b-store_result-list {
				@include flex(1);
			}
		}

		@include respond-to(phone) {
			margin-bottom: 20px;
		}
	}
}

.b-store_result {
	&-title {
		@extend %heading-3;
	}

	&-list {
		@include respond-to(not-phone) {
			overflow: auto;
		}
	}

	&-item {
		padding: 10px;
		margin-bottom: 10px;
		background-color: $color-grey_light4;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}

		&.selected {
			background-color: darken($color-grey_light4, 5);
		}
	}
}

.b-store {
	overflow: hidden;
	@include font($font_size-small, false);
	line-height: 1.2;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	justify-content: space-between;

	&-image {
		float: left;
		width: 30%;
		max-width: 100px;
		margin-right: 15px;
		overflow: hidden;
		flex: 1;

		> img {
			width: 100%;
		}
	}

	&-details {
		overflow: hidden;
		flex: 2;
	}

	&-name,
	&-address,
	&-hours {
		margin-bottom: 5px;
	}

		&-address {
				margin: 0 5px;
		}

	&-name {
		@include font($font_size, false);
		font-weight: 600;
	}

	&-link {
		margin-top: 10px;
		font-size: 14px;
		font-weight: bold;
		background-color: transparent;
	}

	&-availability {
				flex: 1;
				text-align: right;

		&:before {
			content: '';
			height: 10px;
			width: 10px;
			display: inline-block;
			border-radius: 50%;
		}

		&.in-stock-msg:before {
			background-color: $color-green;
		}

		&.preorder-msg:before {
			background-color: $color-orange;
		}

		&.backorder-msg:before {
			background-color: $color-orange;
		}

		&.not-available-msg:before {
			background-color: $color-red;
		}
	}
}

.dialog-store_availability {
	max-width: 880px;
}

.b-store_availability_button {
	float: right;

	&-button {
		&.m-text {
			text-decoration: none;
		}
		.b-button-inner {
			text-transform: uppercase;
		}
	}
}
