.b2b-primary_logo {
	.m-logo-pleinSport & {
		@include respond-to(desktop) {
			padding: 15px 0 5px;
		}

		a:before {
			@extend %bg-tiger;
			background-size: 100% auto !important;
			width: 150px;
		}
	}

	.m-logo-billionaire & {
		@include respond-to(desktop) {
			padding: 10px 0 5px;
		}

		a:before {
			@extend %bg-lion
		}
	}

	@include respond-to(desktop) {
		width: 60%;
	}

	@include respond-to(not-desktop) {
		padding-left: 5px;
		padding-right: 5px;

		img {
			height: auto;
			max-height: 100%;
		}
	}
}

.b2b-utility_menu-link {
	@include respond-to(desktop) {
		padding-top: $navigation-height / 2;
		padding-bottom: $navigation-height / 2;
	}
}

.b2b-navigation {
	&-top_link {
		.m-logo-pleinSport & {
			@include respond-to(desktop) {
				min-height: 20px;
				margin-top: 10px;
			}
		}

		.m-logo-billionaire & {
			@include respond-to(desktop) {
				min-height: 20px;
				margin-top: 10px;
			}
		}
	}
  .account_name {
    display: none;
	}
}
