.b-360view {
	&-progress {
		@include font($font_size-h1, false, $font_family-additional);
	}
	> .b-zoom-link {
		&:before {
			@include font(10px, $letter_spacing-medium, $font_family);
		}
	}
}
