// Text inputs, textarea, select


/*
---
name: Text input
tag: inputs
category: form elements
---

```html
	<div class="sg-form_elements-wrapper">
		<div class="f-field js-form_field f-field-text">
			<label class="f-label" for="textinput-example">
				<span class="f-required-indicator"></span>
				<span class="f-label-value">Email Address</span>
			</label>
			<input class="f-textinput input-text required" type="text" id="textinput-example" name="textinput-example" value="" aria-required="true">
			<span id="textinput-example-error" class="error">This field is required.</span>
		</div>
	</div>
```
*/


/*
---
name: Textarea
tag: inputs
category: form elements
---

```html
	<div class="sg-form_elements-wrapper">
		<div class="f-field js-form_field f-field-textarea">
			<label class="f-label" for="textarea-example">
				<span class="f-label-value">Message</span>
			</label>
			<textarea class="f-textarea input-textarea " id="textarea-example" name="textarea-example" rows="4" cols="10" data-character-limit="250"></textarea>
			<div class="char-count">You have <span class="char-remain-count">250</span> characters left out of <span class="char-allowed-count">250</span></div>
		</div>
	</div>
```
*/


/*
---
name: Select
tag: inputs
category: form elements
---

```html
	<div class="sg-form_elements-wrapper">
		<div class="f-field js-form_field f-field-select">
			<label class="f-label" for="textarea-example">
				<span class="f-label-value">Address</span>
			</label>
			<select class="f-select">
				<option label="United States" value="us" selected="">United States</option>
				<option label="United States" value="us" selected="">United States</option>
				<option label="United States" value="us" selected="">United States</option>
				<option label="United States" value="us" selected="">United States</option>
			</select>
		</div>
	</div>
```
*/


/*
---
name: Checkbox
tag: inputs
category: form elements
---

```html
	<div class="sg-form_elements-wrapper">

		<div class="f-field f-field-inline">
			<input class="f-checkbox input-checkbox " type="checkbox" id="checkbox-example" name="checkbox-example" value="true">
			<label class="f-label" for="checkbox-example">
				<span class="f-label-value">Remember Me</span>
			</label>
		</div>
	</div>
```
*/


/*
---
name: Radio buttons
tag: inputs
category: form elements
---

```html
	<div class="sg-form_elements-wrapper">

		<div class="f-field js-form_field f-field-radio">
			<label class="f-label" for="radio-example">
				<span class="f-label-value">Is this a gift?</span>
			</label>
			<div class="f-radio-group">
				<input class="f-radio input-radio" type="radio" id="radio-example_1" name="radio-example" value="true">
				<label class="f-label" for="radio-example_1">
					<span class="f-label-value">Yes</span>
				</label>
			</div>
			<div class="f-radio-group">
				<input class="f-radio input-radio" type="radio" checked="checked" id="radio-example_2" name="radio-example" value="false">
				<label class="f-label" for="radio-example_2">
					<span class="f-label-value">No</span>
				</label>
			</div>
		</div>
	</div>
```
*/


@mixin form-textinputs(
	$input-font_size: $font_size,
	$input-letter_spacing: $letter_spacing
) {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	
	@include font($input-font_size, $input-letter_spacing, $font_family);
	@include transition;
	display: inline-block;
	vertical-align: top;
	outline: none;
	resize: none;
	width: 360px;
	max-width: 100%;
	padding: 5px 12px;
	border: 1px solid $color-form-border;
	color: $color-form-text;

	@include respond-to(phone) {
		width: 100%;
	}

	&.error {
		border-color: $color-error;
	}
	
	&:disabled {
		opacity: 0.3;
		pointer-events: none;
	}
}

@mixin form-textarea {
	overflow: auto;
}

@mixin form-select($simple: true) {
	@if $simple {
		padding-right: 25px;
		background-image: url(select.png);
		background-size: 32px auto;
		background-position: 100% 50%;
	} @else {
		padding-right: 42px;
		background-image: url(select.png), linear-gradient(to bottom, $color-form-border 0%, $color-form-border 100%);
		background-size: 32px auto, 1px 100%;
		background-position: 100% 50%, calc(100% - 32px) 0;

		&.error {
			background-image: url(select.png), linear-gradient(to bottom, $color-red 0%, $color-red 100%);
		}

		&:hover,
		&:focus,
		&.error:hover {
			background-image: url(select.png), linear-gradient(to bottom, $color-form-border_hover 0%, $color-form-border_hover 100%);
		}
	}
	
	background-repeat: no-repeat;
	cursor: pointer;
	white-space: nowrap;
	
	&::-ms-expand {
		display: none;
	}
}

@mixin form-checkbox(
	$checkbox-size: 16px
) {
	@extend %visually-hidden;

	~ .f-label {
		cursor: pointer;
		line-height: $checkbox-size;
		
		> .f-label-value {
			display: inline;
			vertical-align: middle;
		}
		
		.f-label-image {
			max-width: 45px;
			vertical-align: middle;
			margin: -5px 5px -5px -5px;
		}
		
		&:before {
			@include transition;
			content: '';
			box-sizing: border-box;
			display: inline-block;
			vertical-align: middle;
			width: $checkbox-size;
			height: $checkbox-size;
			margin-right: 10px;
			font-size: $checkbox-size - 4;
			border: 1px solid $color-form-border;
			line-height: $checkbox-size - 2;
			text-align: center;
		}
	}
	
	&:disabled ~ .f-label {
		opacity: 0.5;
		cursor: default;
	}
	
	~ .f-caption {
		margin-left: $checkbox-size + 10;
	}
}

@mixin form-radio {
	~ .f-label:before {
		border-radius: 100%;
		font-size: 6px;
	}
}

@include placeholder {
	color: $color-form-details;
}
