#wrapper.pt_categorylanding .b-content {
    background-color: #f4f3f4;
}
._masterSection {
    display: block;
    max-width: 1600px;
    margin: 0 auto;

    &-imageWrapper {
        background: $color-grey_light5;
        margin-bottom: 25px;
        display: flex;
        min-height: 365px;

        @include respond-to(phone-mini) {
            min-height: 150px;
        }

        &-content {
            font-family: $font_family-additional;
            text-transform: uppercase;
            letter-spacing: $letter_spacing;
            padding: 30px;
            text-align: right;
            width: 33%;
            min-width: 500px;
            display: flex;
            flex-direction: column;

            @include respond-to(tablet) {
                width: 50%;
                min-width: auto;
            }
            @include respond-to(phone) {
                padding: 15px;
                width: 50%;
                min-width: auto;
            }

            @include respond-to(phone-mini) {
                padding: 15px;
            }

            &-title2 {
                font: 800 2em/1.5em 'Open Sans', sans-serif;
                padding-bottom: 5px;
                letter-spacing: 10px;
                margin-right: -10px;
                text-transform: uppercase;

                @include respond-to(phone-mini) {
                    line-height: 1.5em;
                    letter-spacing: 5px;
                    padding-bottom: 5px;
                    margin-right: -5px;
                    font-size: 2rem
                }
            }

            &-title3 {
                font-weight: 800;
                text-transform: uppercase;
                font-size: 1.5em;
                letter-spacing: 6px;
                padding-left: 120px;
                margin-right: -6px;
                flex: 2 2 100%;

                @include respond-to(phone-mini) {
                    padding-left: 0;
                    letter-spacing: 3px;
                    margin-right: -3px;
                }
            }

            &-made {
                flex: 1 1 10%;
                padding-bottom: 25px;

                @include respond-to(phone-mini) {
                    display: none;
                }
            }

            &-description {
                text-transform: none;
                font-weight: 300;
                padding-left: 49px;
                letter-spacing: 1px;

                @include respond-to(phone-mini) {
                    display: none;
                }
            }
        }

        &-image {
            max-width: 50%;
            width: 33%;
            background: center center/cover no-repeat;
            @include respond-to(tablet) {
                width: 50%;
            }
            @include respond-to(phone) {
                width: 50%;
            }
        }
    }
}
