.breadcrumb {
	@include b-breadcrumbs;
	@include order(1);
	background-color: $color-black;
	padding: 0 $gutter-global;
	line-height: 14px;
	text-align: left;
	text-transform: uppercase;

	&-element {
		padding: 8px 0;
		&:after {
			margin: 0 3px;
		}

		&:last-child {
			font-weight: 600;
			color: $color-grey_light2;
		}
	}

	@include respond-to(phone) {
		text-align: center;
	}
}
