// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$cards:  (
	'alipay': (-100px, 0px),
	'amex': (-200px, -60px),
	'bank_transfer': (0px, -60px),
	'cash_on_delivery': (-100px, -60px),
	'credit_card': (0px, -120px),
	'cup': (-100px, -120px),
	'directebanking': (-200px, 0px),
	'discover': (0px, 0px),
	'ideal': (-200px, -120px),
	'jcb': (0px, -180px),
	'klarna': (-100px, -300px),
	'klarna_account': (-100px, -300px),
	'maestro': (-200px, -180px),
	'mc': (0px, -240px),
	'paypal': (-100px, -240px),
	'visa': (-200px, -240px),
	'default': (100%, 100%),
	'wechatpay': (0px, -300px),
	'crypto_credit': (-200px, -300px),
	'coinify_payments': (-200px, -300px),
);

$cards_width: 300px;

.card-icon:before,
%card-icon:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 50px;
	height: 30px;
	background: url(../images/sprite_cards.png) -100% -100% no-repeat;
	background-size: $cards_width/2 auto;
}

@each $key, $pos in $cards {
	.card-icon-#{$key}:before,
	%card-icon-#{$key}:before {
		background-position: nth($pos, 1)/2 nth($pos, 2)/2;
	}
}

.card-icon-googlepay:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 50px;
	height: 30px;
	background: url(../images/googlepay@2x.png) -100% -100% no-repeat;
	background-size: 50px auto;
	background-position: 0px -2px;
}

.card-icon-yoomoney::before {
  background: url(../images/YooMoney.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

