.b-product_tile {
	&-quickview {
		$qv-width: 22px;
		$qv-height: 1.732 * $qv-width;
		
		$qv-color: $color-black;
		$qv-bg: $color-white;
		
		position: absolute;
		top: 5px; right: 5px;
		width: $qv-width;
		height: $qv-height;
		margin: 0 $qv-width/2;
		background-color: $qv-bg;
		@include font(0, 0);
		line-height: $qv-height;
		color: $qv-color;
		@include transition();
		
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0; right: 100%;
			border-style: solid;
			border-width: $qv-height/2 $qv-width/2 $qv-height/2 0;
			border-color: $color-none $qv-bg;
			@include transition(border-color);
		}
	
		&:after {
			right: auto; left: 100%;
			border-width: $qv-height/2 0 $qv-height/2 $qv-width/2;
		}
		
		> span:before {
			@include font($qv-width, 0);
		}
	
		&:hover {
			background-color: $qv-color;
			color: $qv-bg;
			
			&:before,
			&:after {
				border-color: $color-none $qv-color;
			}
		}
	
		.b-product_carousel & {
			display: none;
		}
	}
}

.items-per-page {
	#grid-paging-header {
		padding: 0 35px 0 5px;
	}
}
