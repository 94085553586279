// # Mixin for grid layout
@mixin column($device: '', $grid-columns: 12, $grid-widths : (37.5%, 62.5%)) {
	@for $i from 1 through $grid-columns {
		$value: $i * percentage(1 / $grid-columns);
		&-#{round($value / 1%)}#{$device} {
			width: $value;
		}
	}

	@each $value in $grid-widths {
		&-#{round($value / 1%)}#{$device} {
			width: $value;
		}
	}
}
@mixin grid($grid-columns: 12, $grid-widths: (37.5%, 62.5%), $grid-gutter: $gutter-global / 2) {
	// Container
	.grid-container {
		margin-left: -$grid-gutter;
		margin-right: -$grid-gutter;

		[class*="grid-col"] {
			padding-left: $grid-gutter;
			padding-right: $grid-gutter;
		}
	}

	// Columns
	[class*="grid-col"] {
		float: left;
	}

	.grid-col {
		// width desktop
		@include column('', $grid-columns, $grid-widths);

		// width tablet
		@include respond-to(tablet) {
			@include column('-tablet', $grid-columns, $grid-widths);
		}

		// width phone
		@include respond-to(phone) {
			@include column('-mobile', $grid-columns, $grid-widths);
		}
	}
}


// Init grid
@include grid;
