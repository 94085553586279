.b-maneuver {
	display: block;
	width: 16px;
	height: 16px;
	background: url(../images/sprite_maneuvers.png) -100% -100% no-repeat;
	background-size: 19px 630px;
	
	&.ferry {
		background-position: 0 -614px;
	}

	&.ferry-train {
		background-position: 0 -566px;
	}

	&.merge {
		background-position: 0 -143px;
	}

	&.straight {
		background-position: 0 -534px;
	}

	&.fork-left {
		background-position: 0 -550px;
	}

	&.ramp-left {
		background-position: 0 -598px;
	}

	&.roundabout-left {
		background-position: 0 -197px;
	}

	&.turn-left,
	&.keep-left {
		background-position: 0 -413px;
	}

	&.turn-sharp-left {
		background-position: 0 0;
	}

	&.turn-slight-left {
		background-position: 0 -378px;
	}

	&.uturn-left {
		background-position: 0 -305px;
	}

	&.fork-right {
		background-position: 0 -499px;
	}

	&.ramp-right {
		background-position: 0 -429px;
	}

	&.roundabout-right {
		background-position: 0 -232px;
	}

	&.turn-right,
	&.keep-right {
		background-position: 0 -483px;
	}

	&.turn-sharp-right {
		background-position: 0 -582px;
	}

	&.turn-slight-right {
		background-position: 0 -51px;
	}

	&.uturn-right {
		background-position: 0 -35px;
	}
}
