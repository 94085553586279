// Adyen payment methods markup is populated by js, so class names are used as is
.payment-method-adyen {
	@include respond-to(not-phone) {
        .additionalFields:not(:empty) {
            max-width: 450px;
            padding: 15px 15px 15px 40px;
		}
	}
}
.payment-method-adyen,
.payment-method-options {
    // This class is used both for adyen-generated and native SF methods
	.paymentMethod {
		margin-bottom: 20px;
		border-bottom: none;
        // Fake radio buttons
        input[type="radio"] {
            display: none;
            &:checked ~ label {
                &:before {
                    content: "\f138";
                    color: #111;
                }
            }
        }
        // Selected method
        & input:checked + img + label,
        & input:checked + label {
            font-weight: bold !important;// override inline
        }
		& > label {
			width: 100%;
            line-height: 2.2;
            font-weight: normal !important;// override inline
			&:before {
				content: "";
				float: left;
				display: inline-block;
				width: 15px;
				height: 15px;
				margin: 8px 10px 0 0;
				font-family: "iconfont";
				font-size: 6px;
				line-height: 14px;
				text-align: center;
				border: 1px solid #111;
				border-radius: 100%;
				cursor: pointer;
			}
		}
		&_img {
			width: 50px;
			height: 30px;
            margin-right: 10px;
		}
	}

}
